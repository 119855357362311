import { useAvailableWorkatoConnections } from "@/dataHooks/workato/available-workato-connections.dataHook";
import {
  WorkatoConnectionPayloadType,
  WorkatoCreatedFolderType,
  WorkatoProjectResponseType,
} from "@trunk-tools/txt-shared";
import { Form, Text } from "@trunk-tools/ui";
import React from "react";

interface ListConnectionsProps {
  workatoProject: WorkatoProjectResponseType;
  setSelectedConnection: (
    selectedConnection: WorkatoConnectionPayloadType | null,
  ) => void;
  selectedWorkspaceFolder: WorkatoCreatedFolderType;
}

export const ListConnections: React.FC<ListConnectionsProps> = ({
  workatoProject,
  setSelectedConnection,
  selectedWorkspaceFolder,
}) => {
  const { availableWorkatoConnections, isLoading } =
    useAvailableWorkatoConnections(
      workatoProject.id,
      selectedWorkspaceFolder.id,
    );

  return (
    <div>
      <Form.Provider
        validators={{
          selectedConnection: Form.validators.required,
        }}
        initialValues={{}}
        onSubmit={async (
          formFields: Record<string, string | number | string[]>,
        ) => {
          const selectedConnection =
            availableWorkatoConnections.available_workato_connections.find(
              (workatoConnection: { id: number }) =>
                workatoConnection.id === Number(formFields.selectedConnection),
            );

          if (!selectedConnection) {
            return;
          }

          setSelectedConnection(selectedConnection);
        }}
      >
        <>
          <Text.Header className="mb-3">Select A Connection</Text.Header>
          <div className="mb-5">
            <Form.Fields.SearchableSelect
              name="selectedConnection"
              items={availableWorkatoConnections.available_workato_connections.map(
                (workatoConnection: { name: string; id: number }) => ({
                  label: workatoConnection.name,
                  value: String(workatoConnection.id),
                }),
              )}
              isAsync={true}
              isLoading={isLoading}
            />
          </div>
        </>
      </Form.Provider>
    </div>
  );
};
