import {
  WorkatoCreatedFolderType,
  WorkatoProjectResponseType,
} from "@trunk-tools/txt-shared";
import { useState } from "react";
import { EstablishConnection } from "./EstablishConnection.current.page";
import { SelectWorkspaceProject } from "./SelectWorkspaceProject.current.page";
import { UACAccountType } from "@trunk-tools/txt-shared/src/schemas/uac.schema";
import { SelectUACProject } from "./SelectUACProject.current.page";
import { SelectWorkspaceFolder } from "./SelectWorkspaceFolder.current.page";

export const NewIntegration = () => {
  const [selectedWorkspaceProject, setSelectedWorkspaceProject] =
    useState<WorkatoProjectResponseType | null>(null);
  const [uacAccount, setUACAccount] = useState<UACAccountType | null>(null);
  const [selectedWorkspaceFolder, setSelectedWorkspaceFolder] =
    useState<WorkatoCreatedFolderType | null>(null);

  if (!selectedWorkspaceProject) {
    return (
      <SelectWorkspaceProject
        setSelectedWorkspaceProject={setSelectedWorkspaceProject}
      />
    );
  }

  if (!selectedWorkspaceFolder) {
    return (
      <SelectWorkspaceFolder
        setSelectedWorkspaceFolder={setSelectedWorkspaceFolder}
        selectedWorkspaceProject={selectedWorkspaceProject}
      />
    );
  }

  if (!uacAccount) {
    return (
      <EstablishConnection
        workatoProject={selectedWorkspaceProject}
        setUACAccount={setUACAccount}
        selectedWorkspaceFolder={selectedWorkspaceFolder}
      />
    );
  }

  return (
    <SelectUACProject
      selectedWorkspaceProject={selectedWorkspaceProject}
      uacAccount={uacAccount}
      selectedWorkspaceFolder={selectedWorkspaceFolder}
    />
  );
};
