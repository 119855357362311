import { useUsefulnessVote, UsefulnessVoteProps } from "dataHooks";
import { FaThumbsUp } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import { tw } from "@trunk-tools/ui";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";

interface CheckboxReasonProps {
  reason: string;
  reasons: string[];
  setReasons: (reasons: string[]) => void;
}

const CheckboxReason = ({
  reason,
  reasons,
  setReasons,
}: CheckboxReasonProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              const newReasons = new Set([...reasons, reason]);
              setReasons([...newReasons]);
            } else {
              setReasons(reasons.filter((r) => r !== reason));
            }
          }}
          checked={reasons.includes(reason)}
          sx={{
            color: "white",
          }}
        />
      }
      label={reason}
    />
  );
};

interface FeedbackModalProps {
  feedbackModalOpen: boolean;
  onDialogClose: () => void;
  onSave: (reasons: string[], additionalReason: string) => void;
  reasons: string[];
  setReasons: (reasons: string[]) => void;
  additionalReason: string;
  setAdditionalReason: (reason: string) => void;
  itemType: UsefulnessVoteProps["itemType"];
}

const FeedbackModal = ({
  feedbackModalOpen,
  onDialogClose,
  onSave,
  reasons,
  setReasons,
  additionalReason,
  setAdditionalReason,
  itemType,
}: FeedbackModalProps) => {
  let readableItemType = "";
  switch (itemType) {
    case "QUESTION_ANSWER":
      readableItemType = "ANSWER";
      break;
    case "QUESTION_ANSWER_DOCUMENT":
      readableItemType = "DOCUMENT";
      break;
    case "QUESTION_SEARCH_RESULT_DOCUMENT":
      readableItemType = "DOCUMENT";
      break;
    case "SCHEDULE_TASK_DOCUMENTS":
      readableItemType = "DOCUMENT";
      break;
    default:
      readableItemType = "ITEM";
  }

  return (
    <>
      <Dialog open={feedbackModalOpen} onClose={onDialogClose}>
        <DialogTitle>PROVIDE FEEDBACK ON {readableItemType}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onDialogClose}
          size="large"
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
            padding: 0,
          }}
        >
          <IoCloseOutline className="w-10 h-10" />
        </IconButton>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {[
            "Outdated documentation",
            "Insufficient response",
            "Wrong document type",
            "Other",
          ].map((item) => (
            <CheckboxReason
              key={item}
              reason={item}
              reasons={reasons}
              setReasons={(reasons) => {
                setReasons(reasons);
                if (!reasons.includes("Other")) {
                  setAdditionalReason("");
                }
              }}
            />
          ))}
          {reasons.includes("Other") && (
            <TextareaAutosize
              onChange={(e) => {
                setAdditionalReason(e.target.value);
              }}
              style={{ color: "black" }}
              value={additionalReason}
              autoFocus
              placeholder="Add specific details..."
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            <Button
              color="secondary"
              aria-label="cancel"
              startIcon={<CloseOutlined />}
              onClick={onDialogClose}
              size="large"
            >
              Skip Feedback
            </Button>
          </span>
          <span>
            <Button
              aria-label="submit"
              startIcon={<CheckOutlined />}
              onClick={() => {
                onSave(reasons, additionalReason);
                onDialogClose();
              }}
              disabled={reasons.includes("Other") && additionalReason === ""}
              size="large"
            >
              Submit
            </Button>
          </span>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const UsefulnessVote = ({ itemType, itemId }: UsefulnessVoteProps) => {
  const { votePositive, voteNegative, currentVote } = useUsefulnessVote({
    itemType,
    itemId,
  });

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [reasons, setReasons] = useState<string[]>([]);
  const [additionalReason, setAdditionalReason] = useState("");

  useEffect(() => {
    const feedback = currentVote?.feedback_reasons || [];
    setReasons([...feedback]);
    setAdditionalReason(currentVote?.additional_feedback || "");
  }, [currentVote]);

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className="flex justify-between"
    >
      {feedbackModalOpen && (
        <FeedbackModal
          feedbackModalOpen={feedbackModalOpen}
          onDialogClose={() => setFeedbackModalOpen(false)}
          onSave={voteNegative}
          reasons={reasons}
          setReasons={setReasons}
          additionalReason={additionalReason}
          setAdditionalReason={setAdditionalReason}
          itemType={itemType}
        />
      )}
      <div className="flex gap-3">
        <FaThumbsDown
          data-pendo-id={`usefulness-vote-negative-${itemType}`}
          onClick={() => {
            voteNegative();
            setFeedbackModalOpen(true);
          }}
          onTouchEnd={() => {
            voteNegative();
            setFeedbackModalOpen(true);
          }}
          className={tw(
            "w-[16px] h-[16px] hover:scale-110 fill-brand top-[4px] relative cursor-pointer",
            {
              value: typeof currentVote === "undefined",
              true: "fill-white",
            },
            {
              value: currentVote?.vote === "NEGATIVE",
              true: "fill-brand",
            },
            {
              value: currentVote?.vote === "POSITIVE",
              true: "fill-black",
            },
          )}
        />
        <FaThumbsUp
          data-pendo-id={`usefulness-vote-positive-${itemType}`}
          onClick={votePositive}
          onTouchEnd={votePositive}
          className={tw(
            "w-[16px] h-[16px] hover:scale-110 cursor-pointer",
            {
              value: typeof currentVote === "undefined",
              true: "fill-white",
            },
            {
              value: currentVote?.vote === "NEGATIVE",
              true: "fill-black",
            },
            {
              value: currentVote?.vote === "POSITIVE",
              true: "fill-brand",
            },
          )}
        />
      </div>
    </div>
  );
};
