export const pluralize = ({
  singular,
  plural,
  count,
}: {
  singular: string;
  plural: string;
  count: number;
}) => {
  return count === 1 ? singular : plural;
};
