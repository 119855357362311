import { createContext } from "react";
import { LocalCorpusFilterFilter } from "./CorpusFilters.types";
import { UACProject } from "@trunk-tools/txt-shared/src/schemas/uac.schema";

type UACProjectsByService = {
  id: string;
  serviceLabel: string;
  uacProjects: UACProject[];
};

interface CorpusFiltersContextType {
  allUACProjects: UACProject[];
  allUACProjectsByService: UACProjectsByService[];
  availableUACProjectsByService: UACProjectsByService[];
  onFilterBlockChange: (params: { filters: LocalCorpusFilterFilter }) => void;
  onFilterBlockDelete: (params: { filterBlockId: string }) => void;
}

export const CorpusFiltersContext = createContext<CorpusFiltersContextType>({
  allUACProjects: [],
  allUACProjectsByService: [],
  availableUACProjectsByService: [],
  onFilterBlockChange: () => void 0,
  onFilterBlockDelete: () => void 0,
});
