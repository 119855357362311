import { IoChevronDown, IoCloseCircleOutline } from "react-icons/io5";
import { cn } from "@trunk-tools/ui";
import { Tooltip } from "@mui/material";

export const CorpusFilterPopupTrigger = ({
  label,
  iconOrImg,
  iconContainerClassName,
  iconBackgroundColor,
  showClear,
  disabled,
  onClick,
  onClear,
}: {
  label: string;
  iconOrImg: React.ReactNode;
  iconContainerClassName?: string;
  iconBackgroundColor?: string;
  showClear?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClear?: () => void;
}) => {
  return (
    <Tooltip
      title={disabled ? "Select an integration to pick files and folders" : ""}
      placement="top"
      arrow
    >
      <div
        className={cn(
          "flex flex-row items-center bg-white rounded-sm min-w-[270px] max-w-full desktop:max-w-[270px] pr-2",
          {
            "cursor-pointer": !disabled,
            "opacity-50 cursor-default": disabled,
          },
        )}
        onClick={onClick}
      >
        <div
          className={cn(
            "px-2.5 py-2 border-r border-black shrink-0",
            iconContainerClassName,
          )}
          style={
            iconBackgroundColor ? { backgroundColor: iconBackgroundColor } : {}
          }
        >
          {iconOrImg}
        </div>
        <p className="text-black px-3 grow truncate">{label}</p>
        <div className="flex items-center shrink-0 gap-2">
          {showClear && (
            <IoCloseCircleOutline
              className="w-4 h-4 text-black"
              onClick={(e) => {
                if (onClear) {
                  e.preventDefault();
                  e.stopPropagation();
                  onClear();
                }
              }}
            />
          )}

          <IoChevronDown className="w-4 h-4 text-black" />
        </div>
      </div>
    </Tooltip>
  );
};
