import { api } from "@/dataHooks/helpers/APIClient";
import { useCurrentProject } from "../project.datahook";
import useSWR from "swr";

export const useAvailableWorkatoProjects = () => {
  const { currentProject } = useCurrentProject();

  const { data: workatoProjects, isLoading } = useSWR(
    ["availableWorkatoProjects", currentProject.id],
    () =>
      api.getWorkatoProjects({
        trunkToolsProjectId: currentProject.id,
      }),
    {
      suspense: true,
    },
  );

  return {
    workatoProjects,
    isLoading,
  };
};
