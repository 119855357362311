import { api } from "@/dataHooks/helpers/APIClient";
import { AgaveSourceSystemsType } from "@trunk-tools/txt-shared";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { Form, Text } from "@trunk-tools/ui";
import { Button } from "@mui/material";
import { useCurrentProject } from "dataHooks";

export const ActivateUACProject = ({
  potentialProjects,
  searchPotentialProjects,
  isLoading,
  uacAccount,
  backToSearch,
}: {
  potentialProjects: { name: string; id: string }[];
  sourceSystem?: AgaveSourceSystemsType;
  searchPotentialProjects: (search: string) => void;
  backToSearch?: () => void;
  isLoading: boolean;
  uacAccount:
    | Awaited<ReturnType<typeof api.uacPublicTokenExchange>>
    | undefined;
}) => {
  const { currentProject } = useCurrentProject();
  const idNavigate = useIdNavigate();

  return (
    <>
      <Form.Provider
        validators={{
          selectedUACProject: Form.validators.required,
        }}
        initialValues={{
          selectedUACProject: "",
        }}
        onSubmit={async (
          formFields: Record<string, string | number | string[]>,
        ) => {
          const selectedProject = potentialProjects.find(
            (project) => project.id === formFields.selectedUACProject,
          );
          if (selectedProject && uacAccount) {
            const { uacProject } = await api.activateUACProject({
              project_id: currentProject.id,
              uac_project_id: selectedProject.id,
              name: selectedProject.name,
              uac_account_id: uacAccount.id,
            });
            idNavigate(`/integrations/${uacProject.id}`);
          }
        }}
      >
        <>
          <Text.Header className="mb-3">Select A Project To Sync</Text.Header>
          <div className="mb-5">
            <Form.Fields.SearchableSelect
              name="selectedUACProject"
              items={potentialProjects.map(
                (uacProject: { name: string; id: string }) => ({
                  label: uacProject.name,
                  value: uacProject.id,
                }),
              )}
              isAsync={true}
              onSearch={searchPotentialProjects}
              isLoading={isLoading}
            />
          </div>
        </>
      </Form.Provider>
      {backToSearch && (
        <div className="w-15 flex mt-2">
          <Button color="secondary" size="small" onClick={() => backToSearch()}>
            Back
          </Button>
        </div>
      )}
    </>
  );
};
