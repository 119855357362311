import { useCanDoOrRedirect } from "@/hooks/useCanDo";
import { ProjectPermission } from "@trunk-tools/txt-shared";
import { LoggedInLayout as OldLoggedInLayout } from "@/layouts/LoggedIn.layout.OLD";
import { Integrations as LegacyIntegrationsPage } from "./legacy/Integrations.legacy.page";

export function IntegrationsPage() {
  const { can } = useCanDoOrRedirect({
    permission: ProjectPermission.ManageDocuments,
  });

  if (can) {
    return (
      <OldLoggedInLayout>
        <LegacyIntegrationsPage />
      </OldLoggedInLayout>
    );
  } else {
    return null;
  }
}
