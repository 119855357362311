import { useWindowSize } from "usehooks-ts";

export const useResponsive = () => {
  const { width: windowWidth } = useWindowSize();

  const isDesktop = windowWidth >= 940;

  return {
    windowWidth,
    isMobile: !isDesktop,
    isDesktop,
  };
};
