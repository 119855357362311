import { Text, Skeleton } from "@trunk-tools/ui";
import { Button } from "@mui/material";
import { TbPlugConnected } from "react-icons/tb";
import { ConstrainWidth } from "@trunk-tools/ui";
import { useAgaveAccountSetup } from "dataHooks";
import { ActivateUACProject } from "../../DocumentSync/ActivateUACProject.component";
import { UacProjectSearchFilter } from "../../DocumentSync/UacProjectSearchFilter.component";

export const NewIntegration = () => {
  const {
    getAgaveLinkToken,
    agaveLinkIsOpen,
    searchPotentialProjects,
    uacPotentialProjects,
    uacSourceSystem,
    isLoadingPotentialProjects,
    uacAccount,
    uacAccountStatus,
    projectSearchFilter,
    setProjectSearchFilter,
  } = useAgaveAccountSetup();

  if (
    uacAccount?.source_system === "SharePoint_Online" &&
    projectSearchFilter === ""
  ) {
    return (
      <UacProjectSearchFilter setProjectSearchFilter={setProjectSearchFilter} />
    );
  }

  if (uacAccountStatus === "Ready") {
    return (
      <ActivateUACProject
        potentialProjects={uacPotentialProjects}
        searchPotentialProjects={searchPotentialProjects}
        sourceSystem={uacSourceSystem}
        isLoading={isLoadingPotentialProjects}
        uacAccount={uacAccount}
        backToSearch={
          uacAccount?.source_system === "SharePoint_Online"
            ? () => setProjectSearchFilter("")
            : undefined
        }
      />
    );
  }

  if (agaveLinkIsOpen) {
    return <Skeleton />;
  }

  return (
    <ConstrainWidth maxWidth="maximum">
      <Text.Paragraph className="text-center mb-3">
        Click the “CONNECT” button to login to the service of your choice and
        authorize a connection to TrunkText.
      </Text.Paragraph>
      <Text.Paragraph className="mb-6 text-center">
        Once connected, we’ll walk through a simple process to import RFIs,
        Submittals, Specs, and other project documents into TrunkText.
      </Text.Paragraph>
      <Button
        startIcon={<TbPlugConnected />}
        onClick={() => getAgaveLinkToken()}
        fullWidth
      >
        Connect
      </Button>
    </ConstrainWidth>
  );
};
