import { api } from "@/dataHooks/helpers/APIClient";
import { useCurrentProject } from "../project.datahook";
import useSWR from "swr";

export const useAvailableWorkatoFolders = (workatoProjectId: string) => {
  const { currentProject } = useCurrentProject();

  const { data: workatoFolders, isLoading } = useSWR(
    ["availableWorkatoFolders", currentProject.id],
    () =>
      api.getAvailableWorkatoFolders({
        trunkToolsProjectId: currentProject.id,
        workatoProjectId,
      }),
    {
      suspense: true,
    },
  );

  return {
    workatoFolders,
    isLoading,
  };
};
