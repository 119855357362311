import { useDocument } from "@/dataHooks/documents.dataHook";
import {
  ExpandableTile,
  LoadingRectangle,
  LoadingThumbsDown,
  LoadingThumbsUp,
} from "@trunk-tools/ui";
import { Button } from "@mui/material";
import csvFileIcon from "public/files/brand/file-CSV.svg";
import docFileIcon from "public/files/brand/file-DOC.svg";
import genericFileIcon from "public/files/brand/file-generic.svg";
import pdfFileIcon from "public/files/brand/file-PDF.svg";
import xerFileIcon from "public/files/brand/file-XER.svg";
import xlsFileIcon from "public/files/brand/file-XLS.svg";
import { UsefulnessVote } from "./UsefulnessVote.component";
import { UsefulnessVoteProps, useQuestionDocument } from "dataHooks";
import { Suspense, useState } from "react";
import { download } from "@/utils/download";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { FeatureFlagGate } from "@/components/FeatureFlagGate.component";
import { LoadingButton } from "@mui/lab";

interface MainContentProps {
  fileType: string;
  filename: string;
  fileFolder: string;
  fileInformation: string;
  onClick?: () => void;
}

const DocumentTileMainContent = ({
  fileType,
  filename,
  fileFolder,
  fileInformation,
  onClick,
}: MainContentProps) => {
  let fileIcon: string;

  switch (fileType) {
    case "pdf":
      fileIcon = pdfFileIcon;
      break;
    case "doc":
    case "docx":
      fileIcon = docFileIcon;
      break;
    case "xls":
    case "xlsx":
      fileIcon = xlsFileIcon;
      break;
    case "csv":
      fileIcon = csvFileIcon;
      break;
    case "xer":
      fileIcon = xerFileIcon;
      break;
    default:
      fileIcon = genericFileIcon;
      break;
  }

  return (
    <div
      className={`flex flex-row gap-x-4${
        typeof onClick !== "undefined" ? " cursor-pointer" : ""
      }`}
      onClick={onClick}
    >
      <div>
        <img
          className="min-w-[30px] min-h-[32px]"
          src={fileIcon}
          alt="file icon"
        />
      </div>
      <div className="flex flex-col gap-y-1 justify-center">
        <div
          className={`${
            onClick !== undefined ? "text-brand underline cursor-pointer" : ""
          }`}
        >
          {filename.trim().length > 0
            ? `${fileFolder}/${filename}`
            : "Untitled"}
        </div>

        <FeatureFlagGate
          enabled={() => <div>{fileInformation}</div>}
          disabled={() => null}
          flagKey={(FlagKey) => FlagKey.ShowDocumentType}
        />
      </div>
    </div>
  );
};

const DocumentTileSubContent = ({
  itemId,
  itemType,
  documentId,
  questionId,
}: {
  documentId: string;
  questionId: string;
} & UsefulnessVoteProps) => {
  const { document } = useDocument({ documentId });
  const [waitingForDownload, setWaitingForDownload] = useState(false);
  const { url } = useQuestionDocument({ questionId, documentId });

  return (
    <div className="h-full flex flex-row items-center gap-x-6">
      <div className="uppercase desktop:hidden">
        did this doc help you find your answer?
      </div>
      <UsefulnessVote itemId={itemId} itemType={itemType} />
      <div className="hidden desktop:block" data-pendo-id="document-download">
        {url ? (
          <LoadingButton
            startIcon={<FileDownloadOutlinedIcon />}
            color="primary"
            size="small"
            loading={waitingForDownload}
            onClick={async () => {
              setWaitingForDownload(true);
              download(url, document.name);
              setWaitingForDownload(false);
            }}
          >
            Download
          </LoadingButton>
        ) : (
          <Button color="error" size="small">
            Can't Download
          </Button>
        )}
      </div>
    </div>
  );
};

// Discriminated union on the `showSubContent` property
type DocumentTileProps = (
  | {
      showSubContent: false;
    }
  | ({
      showSubContent: true;
      questionId: string;
    } & UsefulnessVoteProps)
) & { documentId: string; onClick?: () => void; forceFullWidth?: boolean };

const DocumentTileInner = (props: DocumentTileProps) => {
  const { documentId, onClick, showSubContent, forceFullWidth } = props;
  const { document } = useDocument({ documentId: props.documentId });

  return (
    <ExpandableTile
      mainContent={
        <DocumentTileMainContent
          fileInformation={`${document.type}${
            document.source ? ` | ${document.source}` : ""
          }`}
          fileType={document.name.split(".").pop() || ""}
          fileFolder={document.folder}
          filename={document.name}
          onClick={onClick}
        />
      }
      subContent={
        showSubContent ? (
          <DocumentTileSubContent
            itemId={props.itemId}
            itemType={props.itemType}
            documentId={documentId}
            questionId={props.questionId}
          />
        ) : null
      }
      forceFullWidth={forceFullWidth}
    />
  );
};

const SuspenseBoundary = ({ children }: { children: React.ReactNode }) => {
  const mobileSuspense = (
    <div className="h-[170px] min-w-[90%] max-w-[90%] bg-[#1D2020] rounded p-4 desktop:hidden">
      <div className="h-full w-full flex flex-col justify-between">
        <div className="w-full h-[55%] flex flex-row gap-x-2">
          <LoadingRectangle width="w-[40px]" height="h-[40px]" />
          <div className="w-full flex flex-col gap-y-1">
            <LoadingRectangle width="w-full" height="h-[30px]" />
            <LoadingRectangle width="w-[70%]" height="h-[30px]" />
          </div>
        </div>
        <div className="flex flex-row gap-x-2">
          <LoadingRectangle width="w-[60%]" height="h-[20px]" />
          <div className="flex flex-row items-center gap-x-2">
            <LoadingThumbsDown />
            <LoadingThumbsUp />
          </div>
        </div>
      </div>
    </div>
  );

  const desktopSuspense = (
    <div className="h-[99px] w-full bg-[#1D2020] rounded p-4 hidden desktop:block">
      <div className="w-full h-full flex flex-row justify-between items-center">
        <LoadingRectangle width="w-[40px]" height="h-[40px]" />
        <div className="flex flex-col gap-y-2 w-[50%]">
          <LoadingRectangle width="w-full" height="h-[20px]" />
          <LoadingRectangle width="w-[80%]" height="h-[16px]" />
        </div>
        <div className="flex flex-row items-center gap-x-2">
          <LoadingThumbsDown />
          <LoadingThumbsUp />
        </div>
        <LoadingRectangle width="w-[15%]" height="h-[40px]" />
      </div>
    </div>
  );

  return (
    <Suspense
      fallback={
        <>
          {mobileSuspense}
          {desktopSuspense}
        </>
      }
    >
      {children}
    </Suspense>
  );
};

export const DocumentTile = (props: DocumentTileProps) => {
  return (
    <SuspenseBoundary>
      <DocumentTileInner {...props} />
    </SuspenseBoundary>
  );
};
