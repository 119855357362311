import { useState } from "react";
import { api } from "../helpers/APIClient";
import { useCurrentProject } from "../project.datahook";
import { WorkatoCreateFolderPayloadType } from "@trunk-tools/txt-shared";

export const useCreateWorkatoFolder = (workatoProjectId: string) => {
  const { currentProject } = useCurrentProject();

  const [isCreating, setIsCreating] = useState(false);

  const createWorkatoFolder = async (
    workatoFolderPayload: WorkatoCreateFolderPayloadType,
  ) => {
    setIsCreating(true);
    const response = await api.createWorkatoFolder({
      trunkToolsProjectId: currentProject.id,
      workatoProjectId: workatoProjectId,
      workato_folder_payload: workatoFolderPayload,
    });

    setIsCreating(false);
    return response.workato_folder;
  };

  return {
    createWorkatoFolder,
    isCreating,
  };
};
