import { useIdNavigate } from "@/hooks/useIdNavigate";
import { Button } from "@mui/material";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import { useProjectDocumentsStatus, useUACProjects } from "dataHooks";
import { SyncInfoStatus } from "../../DocumentSync/SyncInfoStatus.component";

export const Integrations = () => {
  const idNavigate = useIdNavigate();
  const { uacProjects } = useUACProjects({
    lifecycle_statuses: ["Ready", "Initializing", "Inactive"],
  });
  const { documents_status } = useProjectDocumentsStatus();

  return (
    <>
      <h1 className="mb-6">Integrations</h1>
      <div className="flex justify-between">
        <SyncInfoStatus
          displayName="All"
          documentStatusInfo={documents_status}
        />
      </div>
      <div className="mb-8">
        {uacProjects.map((uacProject) => (
          <div key={uacProject.id} className="mb-4 last-child:mb-0">
            <Button
              startIcon={<ContentPasteOutlinedIcon />}
              onClick={() => idNavigate(`/integrations/${uacProject.id}`)}
              size="small"
              fullWidth
            >
              {uacProject.uac_account.source_system.replaceAll("_", " ")}:{" "}
              {uacProject.name}
            </Button>
          </div>
        ))}
      </div>

      <div>
        <Button onClick={() => idNavigate("/integrations/new")} fullWidth>
          Create New
        </Button>
      </div>
    </>
  );
};
