import { FronteggThemeOptions } from "@frontegg/react";
import { CustomLoginFooter } from "./CustomLoginFooter";

export const themeOptions: FronteggThemeOptions = {
  loginBox: {
    boxStyle: {
      "& button": {
        marginTop: "3px !important",
      },
      padding: "2.25rem !important",
      paddingBottom: "3rem !important",
    },
    boxFooter: () => <CustomLoginFooter />,
  },
};

export const localizations = {
  en: {
    loginBox: {
      login: {
        title: "",
      },
    },
    errors: {},
  },
};
