import { WorkatoProjectPayloadType } from "@trunk-tools/txt-shared";
import { useState } from "react";
import { api } from "../helpers/APIClient";
import { useCurrentProject } from "../project.datahook";

export const useCreateWorkatoProject = () => {
  const { currentProject } = useCurrentProject();

  const [isCreating, setIsCreating] = useState(false);

  const createWorkatoProject = async (
    workatoProjectPayload: WorkatoProjectPayloadType,
  ) => {
    setIsCreating(true);
    const response = await api.createWorkatoProject({
      trunkToolsProjectId: currentProject.id,
      workato_project_payload: workatoProjectPayload,
    });

    setIsCreating(false);
    return response.workato_project;
  };

  return {
    createWorkatoProject,
    isCreating,
  };
};
