import tWhite from "public/trunk_tools_white.svg";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { VisibilityRounded, CloseRounded } from "@mui/icons-material";

type ShareConversationModalProps = {
  isOpen: boolean;
  close: () => void;
  conversationId: string;
};

export const ShareConversationModal = ({
  isOpen,
  close,
  conversationId,
}: ShareConversationModalProps) => {
  return (
    <Dialog open={isOpen} fullScreen>
      <div className="flex flex-col px-6 pb-8 pt-18 desktop:pt-8 h-full">
        <DialogTitle>
          <div className="flex flex-row gap-x-6 items-center pb-4">
            <img src={tWhite} alt="trunktext logo" className="w-13 h-8" />
            <div>share conversation</div>
          </div>
        </DialogTitle>

        <DialogContent>
          <div>{conversationId}</div>
        </DialogContent>

        <DialogActions>
          <div className="w-full flex flex-row justify-between mt-auto">
            <div>
              <Button
                color="secondary"
                onClick={close}
                startIcon={<CloseRounded />}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button onClick={close} startIcon={<VisibilityRounded />}>
                Done
              </Button>
            </div>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};
