import { useAvailableWorkatoProjects } from "@/dataHooks/workato/available-workato-projects.dataHook";
import { useCreateWorkatoProject } from "@/dataHooks/workato/create-workato-project.dataHook";
import { WorkatoProjectResponseType } from "@trunk-tools/txt-shared";
import { Form, Text } from "@trunk-tools/ui";

interface SelectWorkspaceProjectProps {
  setSelectedWorkspaceProject: (
    workatoProject: WorkatoProjectResponseType,
  ) => void;
}

export const SelectWorkspaceProject: React.FC<SelectWorkspaceProjectProps> = ({
  setSelectedWorkspaceProject,
}) => {
  const { workatoProjects, isLoading } = useAvailableWorkatoProjects();
  const { createWorkatoProject, isCreating } = useCreateWorkatoProject();

  return (
    <div>
      <Form.Provider
        validators={{
          selectedWorkatoProject: Form.validators.required,
        }}
        initialValues={{}}
        onSubmit={async (
          formFields: Record<string, string | number | string[]>,
        ) => {
          const selectedWorkatoProject = workatoProjects.workato_projects.find(
            (workatoProject: { id: number }) =>
              workatoProject.id === Number(formFields.selectedWorkatoProject),
          );

          if (!selectedWorkatoProject) {
            return;
          }

          const createdProject = await createWorkatoProject({
            id: selectedWorkatoProject.id,
            name: selectedWorkatoProject.name,
            description: selectedWorkatoProject.description,
            folder_id: selectedWorkatoProject.folder_id,
          });

          setSelectedWorkspaceProject(createdProject);
        }}
      >
        <>
          <Text.Header className="mb-3">Select A Project Workspace</Text.Header>
          <div className="mb-5">
            <Form.Fields.SearchableSelect
              name="selectedWorkatoProject"
              items={workatoProjects.workato_projects.map(
                (workatoProject: { name: string; id: number }) => ({
                  label: workatoProject.name,
                  value: String(workatoProject.id),
                }),
              )}
              isAsync={true}
              isLoading={isLoading || isCreating}
            />
          </div>
        </>
      </Form.Provider>
    </div>
  );
};
