import { useCanDoOrRedirect } from "@/hooks/useCanDo";
import { ProjectPermission } from "@trunk-tools/txt-shared";
import { Integration as LegacyIntegrationPage } from "./legacy/Integration.legacy.page";

export function IntegrationPage() {
  const { can } = useCanDoOrRedirect({
    permission: ProjectPermission.ManageDocuments,
  });

  if (can) {
    return <LegacyIntegrationPage />;
  } else {
    return null;
  }
}
