import { Button } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";

interface Props {
  filename: string;
  csvString: string;
  children?: React.ReactNode;
}

const downloadCSV = (csvData: string, filename: string) => {
  const CSVFile = new Blob([csvData], { type: "text/csv" });
  const temp_link = document.createElement("a");

  temp_link.download = filename;
  temp_link.href = window.URL.createObjectURL(CSVFile);
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  temp_link.click();
  document.body.removeChild(temp_link);
};

export const DownloadStringAsCSVButton = ({
  filename,
  csvString,
  children,
}: Props) => {
  return (
    <Button
      variant="contained"
      startIcon={<FileDownloadOutlined />}
      onClick={() => {
        downloadCSV(csvString, filename);
      }}
    >
      {children}
    </Button>
  );
};
