export const CorpusFilterBlockSection = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col desktop:flex-row flex-wrap desktop:items-center gap-3 desktop:gap-x-2 desktop:gap-y-4">
      {children}
    </div>
  );
};
