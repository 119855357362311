import { ListItemIconProps, PopperProps } from "@mui/material";

export const CORPUS_FILTERLIST_ITEM_ICON_COMMON_PROPS: ListItemIconProps = {
  sx: { minWidth: "28px" },
};

export const CORPUS_FILTER_POPPER_MODIFIERS: PopperProps["modifiers"] = [
  {
    name: "preventOverflow",
    options: {
      boundary: document.body,
      altAxis: true,
    },
  },
  {
    name: "flip",
    options: {
      fallbackPlacements: ["bottom-start", "top-start"],
    },
  },
];
