import {
  WorkatoConnectionPayloadType,
  WorkatoCreatedFolderType,
  WorkatoProjectResponseType,
} from "@trunk-tools/txt-shared";
import { useState } from "react";
import { ListConnections } from "./ListConnections.current.page";
import { ConnectToIntegration } from "./ConnectToIntegration.current.page";
import { UACAccountType } from "@trunk-tools/txt-shared/src/schemas/uac.schema";

export interface EstablishConnectionProps {
  workatoProject: WorkatoProjectResponseType;
  setUACAccount: (uacAccount: UACAccountType) => void;
  selectedWorkspaceFolder: WorkatoCreatedFolderType;
}

export const EstablishConnection: React.FC<EstablishConnectionProps> = ({
  workatoProject,
  setUACAccount,
  selectedWorkspaceFolder,
}) => {
  const [selectedConnection, setSelectedConnection] =
    useState<WorkatoConnectionPayloadType | null>(null);

  if (!selectedConnection) {
    return (
      <ListConnections
        workatoProject={workatoProject}
        setSelectedConnection={setSelectedConnection}
        selectedWorkspaceFolder={selectedWorkspaceFolder}
      />
    );
  }

  return (
    <ConnectToIntegration
      selectedConnection={selectedConnection}
      setUACAccount={setUACAccount}
    />
  );
};
