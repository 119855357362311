import hamburgerWhite from "public/hamburger_white.svg";
import tWhite from "public/trunk_tools_white.svg";
import {
  LoadingHamburger,
  LoadingRectangle,
  useModalToggle,
} from "@trunk-tools/ui";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { useParams } from "react-router-dom";
import { useMaybeConversation } from "@/dataHooks/conversations.dataHook";
import { useInterval } from "@/hooks/useInterval";
import React, { Suspense } from "react";
import { useCurrentProject } from "dataHooks";
import { ShareConversationModal } from "../ShareConversation/ShareConversationModal.component";
import { FeatureFlagGate } from "../FeatureFlagGate.component";
import { Button } from "@mui/material";
import { AddOutlined, IosShareOutlined } from "@mui/icons-material";
import { useResponsive } from "@/hooks/useResponsive";

type AskPageNavbarProps = {
  toggleSidebar: () => void;
};

const SuspenseBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense
      fallback={
        <div className="h-[40px] w-full flex flex-row justify-between">
          <div className="flex flex-row gap-x-6 items-center">
            <LoadingHamburger />
            <img src={tWhite} alt="trunktext logo" className="w-13 h-8" />
            <LoadingRectangle width="w-[200px]" height="h-6" />
          </div>
          <div>
            <LoadingRectangle width="w-10" height="h-10" />
          </div>
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

const AskPageNavbarInner = ({ toggleSidebar }: AskPageNavbarProps) => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const { maybeConversation, refreshConversation } = useMaybeConversation({
    conversationId,
  });
  const idNavigate = useIdNavigate();
  const { currentProject } = useCurrentProject();
  const {
    isOpen: isShareConversationModalOpen,
    open: openShareConversationModal,
    close: closeShareConversationModal,
  } = useModalToggle();

  useInterval(
    refreshConversation,
    maybeConversation?.summary === null &&
      currentProject.project_question_status === "READY_FOR_QUESTIONS"
      ? 2_000
      : null,
  );

  const { isMobile } = useResponsive();

  if (currentProject.project_question_status === "NOT_READY_FOR_QUESTIONS") {
    return (
      <div className="w-full flex flex-row justify-between">
        <div className="flex flex-row items-center gap-x-6">
          <img
            src={hamburgerWhite}
            alt="icon to open sidebar"
            className="cursor-pointer w-8 h-8 mobile:block desktop:hidden"
            onClick={() => toggleSidebar()}
          />
          <img src={tWhite} alt="trunktext logo" className="w-13 h-8" />
          <p className="uppercase">trunktext</p>
        </div>
      </div>
    );
  }

  let navHeaderText: string;
  if (maybeConversation) {
    if (maybeConversation.summary) {
      navHeaderText = maybeConversation.summary;
    } else {
      navHeaderText = "untitled conversation";
    }
  } else {
    navHeaderText = "trunktext";
  }

  return (
    <div className="w-full flex flex-row justify-between gap-x-4">
      <div className="flex flex-row items-center gap-x-6">
        <img
          src={hamburgerWhite}
          alt="icon to open sidebar"
          className="cursor-pointer w-8 h-8 mobile:block desktop:hidden"
          onClick={() => toggleSidebar()}
        />
        <img src={tWhite} alt="trunktext logo" className="w-13 h-8" />
        <p className="uppercase">{navHeaderText}</p>
      </div>
      <div className="flex flex-row gap-x-4 desktop:gap-x-12 items-center">
        {conversationId && (
          <FeatureFlagGate
            flagKey={(FlagKey) => FlagKey.ConversationSharing}
            disabled={() => null}
            enabled={() => (
              <>
                <Button
                  onClick={() => {
                    openShareConversationModal();
                  }}
                  startIcon={isMobile ? undefined : <IosShareOutlined />}
                  variant={isMobile ? "iconbutton" : "contained"}
                >
                  {isMobile ? <IosShareOutlined /> : "Share Conversation"}
                </Button>
                <ShareConversationModal
                  conversationId={conversationId}
                  isOpen={isShareConversationModalOpen}
                  close={closeShareConversationModal}
                />
              </>
            )}
          />
        )}
        <Button
          variant="iconbutton"
          color="success"
          size="small"
          onClick={() => idNavigate("/conversations/new")}
          data-pendo-id="new-conversation-plus"
        >
          <AddOutlined fontSize="small" />
        </Button>
      </div>
    </div>
  );
};

export const AskPageNavbar = (props: AskPageNavbarProps) => {
  return (
    <div className="p-6 flex">
      <SuspenseBoundary>
        <AskPageNavbarInner {...props} />
      </SuspenseBoundary>
    </div>
  );
};
