import { useParams } from "react-router-dom";
import {
  Text,
  useModalToggle,
  Form,
  Takeover,
  Tabs,
  Spinner,
  ThinkingAnimation,
} from "@trunk-tools/ui";
import { Button } from "@mui/material";
import { ChevronRightRounded, CloseRounded } from "@mui/icons-material";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import { useUACProject } from "dataHooks";
import { SuspenseErrorBoundary } from "@/layouts/SuspenseErrorBoundary.layout";
import { ConstrainWidth } from "@trunk-tools/ui";
import {
  SyncInfo,
  DataSets,
  Folders,
} from "../../DocumentSync/ManageDocumentsTabs.component";
import { DocumentSummary } from "../../DocumentSync/DocumentSummary.component";
import { useInterval } from "@/hooks/useInterval";
import { LoadingButton } from "@mui/lab";

export const Integration = () => {
  const { uacProjectId } = useParams<{ uacProjectId: string }>();
  const uac_project_id = uacProjectId!;
  const { isOpen, open, close } = useModalToggle();
  const {
    nothingIsSynced,
    uacProjectSyncConfig,
    watchedFolderIds,
    updateUACProjectSyncConfig,
    refetchUACProjectStatus,
    uacProjectStatus,
    isLoading,
  } = useUACProject({
    uac_project_id,
  });
  useInterval(
    refetchUACProjectStatus,
    uacProjectStatus.uac_project.lifecycle_status !== "Ready" ? 1000 : null,
  );

  if (isLoading) {
    return <ThinkingAnimation />;
  }

  if (uacProjectStatus.uac_project.lifecycle_status !== "Ready") {
    return (
      <ConstrainWidth maxWidth="maximum">
        <div className="flex gap-4">
          <Spinner />
          Initializing project "{uacProjectStatus.uac_project.name}", please
          wait...
        </div>
      </ConstrainWidth>
    );
  }

  return (
    <ConstrainWidth maxWidth="maximum">
      {nothingIsSynced && (
        <>
          <Text.Header className="text-center">
            Connection Successful
          </Text.Header>
          <div className="mb-8 text-center">
            Your account is now linked to TrunkText! Click on the button below
            to start syncing documents, files and other information.
          </div>
        </>
      )}

      <DocumentSummary uacProjectId={uac_project_id} />

      <div className="mt-4">
        <Button
          startIcon={<ContentPasteOutlinedIcon />}
          onClick={open}
          fullWidth
        >
          {nothingIsSynced ? "Setup Document Sync" : "Update Document Sync"}
        </Button>
      </div>

      <div>
        <Form.Provider
          initialValues={{
            RFISync: uacProjectSyncConfig?.rfis || false,
            submittalsSync: uacProjectSyncConfig?.submittals || false,
            specSync: uacProjectSyncConfig?.specs || false,
            drawingSync: uacProjectSyncConfig?.drawings || false,
            meetingSync: uacProjectSyncConfig?.meetings || false,
            selectedFolders: watchedFolderIds || [],
            formSync: uacProjectSyncConfig?.forms || false,
            issueSync: uacProjectSyncConfig?.issues || false,
          }}
          onSubmit={async (
            formFields: Record<string, string | number | string[] | boolean>,
          ) => {
            await updateUACProjectSyncConfig({
              rfis: formFields.RFISync as boolean,
              submittals: formFields.submittalsSync as boolean,
              specs: formFields.specSync as boolean,
              drawings: formFields.drawingSync as boolean,
              folders: formFields.selectedFolders as string[],
              forms: formFields.formSync as boolean,
              issues: formFields.issueSync as boolean,
              meetings: formFields.meetingSync as boolean,
            });
            close();
          }}
          submitButtonText={false}
        >
          {(form) => (
            <Takeover.Container
              isOpen={isOpen}
              onClose={() => {
                // prevent the form resetting during close animation
                setTimeout(form.handleReset, 500);
                close();
              }}
              title="Manage Documents"
              glassHeader={true}
            >
              <>
                <Takeover.Main>
                  <Takeover.Primary>
                    <SuspenseErrorBoundary>
                      <ConstrainWidth maxWidth="maximum">
                        <Tabs
                          tabs={[
                            {
                              name: "Sync Info",
                              content: (
                                <SyncInfo uacProjectId={uac_project_id} />
                              ),
                            },
                            {
                              name: "Data Sets",
                              content: (
                                <DataSets uacProjectId={uac_project_id} />
                              ),
                            },
                            {
                              name: "Folders",
                              content: (
                                <Folders uacProjectId={uac_project_id} />
                              ),
                            },
                          ]}
                        />
                      </ConstrainWidth>
                    </SuspenseErrorBoundary>
                  </Takeover.Primary>
                </Takeover.Main>
                <Takeover.Footer>
                  <Button
                    onClick={() => {
                      // prevent the form resetting during close animation
                      setTimeout(form.handleReset, 500);
                      close();
                    }}
                    color="secondary"
                    startIcon={<CloseRounded />}
                    size="medium"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    startIcon={<ChevronRightRounded />}
                    loading={form.isSubmitting}
                    disabled={!form.dirty}
                  >
                    Save Changes
                  </LoadingButton>
                </Takeover.Footer>
              </>
            </Takeover.Container>
          )}
        </Form.Provider>
      </div>
    </ConstrainWidth>
  );
};
