import { useSharedConversation } from "@/dataHooks/conversations.dataHook";
import { useSearchParams } from "react-router-dom";

export const ShareConversationPage = () => {
  const [searchParams] = useSearchParams();

  const { sharedConversation } = useSharedConversation({
    token: searchParams.get("token") || "",
  });

  return sharedConversation.success ? (
    <div>Valid Token</div>
  ) : (
    <div>Invalid Token</div>
  );
};
