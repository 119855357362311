import { ProjectPermission } from "@trunk-tools/txt-shared";
import { useCanDoOrRedirect } from "@/hooks/useCanDo";
import { LoggedInLayout as OldLoggedInLayout } from "@/layouts/LoggedIn.layout.OLD";
import { FeatureFlagGate } from "@/components/FeatureFlagGate.component";
import { NewIntegration as CurrentNewIntegrationPage } from "./current/NewIntegration.current.page";
import { NewIntegration as LegacyNewIntegrationPage } from "./legacy/NewIntegration.legacy.page";

export function NewIntegrationPage() {
  const { can } = useCanDoOrRedirect({
    permission: ProjectPermission.ManageDocuments,
  });

  if (can) {
    return (
      <OldLoggedInLayout>
        <FeatureFlagGate
          enabled={() => <CurrentNewIntegrationPage />}
          disabled={() => <LegacyNewIntegrationPage />}
          flagKey={(ff) => ff.WorkatoIntegration}
        />
      </OldLoggedInLayout>
    );
  } else {
    return null;
  }
}
