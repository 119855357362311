import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import {
  protectedRoutes,
  isNotLoggedInRoutes,
  openRoutes,
} from "./routeDefinitions";
import {
  ProtectedRoute,
  IsNotLoggedIn,
  RouterSuspenseErrorTopLevelBoundary,
  LoggedInLayout,
  OpenLayout,
  RewriteBusinesses,
  ProjectRouteWrapper,
} from "./routerWrappers";
import { IndexPage } from "@/pages/Index.page";
import { FronteggProvider } from "@frontegg/react";
import { ManageAgentsPage } from "@/pages/ManageAgents.page";
import { NewAgentPage } from "@/pages/NewAgent.page";
import { AskPage } from "@/pages/Ask.page";
import { NewIntegrationPage } from "@/pages/Integrations/NewIntegration.page";
import { IntegrationsPage } from "@/pages/Integrations/Integrations.page";
import { IntegrationPage } from "@/pages/Integrations/Integration.page";
import { QuestionPage } from "@/pages/Question.page";
import { QuestionDocumentPage } from "@/pages/QuestionDocument.page";
import { DocumentPage } from "@/pages/Document.page";
import { ConsentPage } from "@/pages/Consent.page";
import { FeatureFlagGate } from "@/components/FeatureFlagGate.component";
import { NoProjectsPage } from "@/pages/NoProjects.page";
import { localizations, themeOptions } from "@/components/Frontegg/styleConfig";

export const getFronteggConfig = () => {
  const hostname = document.location.hostname.toLowerCase();

  let fronteggContextOptions = {
    baseUrl: "https://app-e2dydlnws5wr.us.frontegg.com",
    clientId: "3f0131df-cfc5-4d89-87c8-641a05ec740c",
    appId: "1a444579-dce9-4981-a73a-2cfd1c82aa9c",
  };

  if (hostname.includes("integration")) {
    fronteggContextOptions = {
      baseUrl: "https://auth.integration.trunktext.com",
      clientId: "9c0a7039-245d-4c19-bf09-aaaa353c9c9d",
      appId: "c609e76d-79a4-4cec-ba13-5b85dcc93fa8",
    };
  } else if (hostname.includes("staging")) {
    fronteggContextOptions = {
      baseUrl: "https://auth.staging.trunktext.com",
      clientId: "fc479f41-a882-4d3d-a833-b76b0cdc3575",
      appId: "6de46e52-7c3f-43bf-bab9-49b1c4b153f6",
    };
  } else if (hostname === "trunktext.com") {
    fronteggContextOptions = {
      baseUrl: "https://auth.trunktext.com",
      clientId: "bbf2ffbc-f8f1-42e2-8151-5d8bf26d020c",
      appId: "3dc825b5-81b3-4d6c-b869-bb5e9ea8a1e5",
    };
  }

  return fronteggContextOptions;
};

const routes = (
  <Route
    path="/*"
    element={
      <FeatureFlagGate
        enabled={() => (
          <Routes>
            <Route
              element={<RouterSuspenseErrorTopLevelBoundary />}
              key="suspense"
            >
              <Route
                path="/businesses/:businessId/*"
                element={<RewriteBusinesses />}
              />
              <Route
                path="/*"
                element={
                  <FronteggProvider
                    contextOptions={getFronteggConfig()}
                    hostedLoginBox={false}
                    themeOptions={themeOptions}
                    localizations={localizations}
                  >
                    <Routes>
                      <Route element={<ProtectedRoute />} key="protected">
                        <Route element={<LoggedInLayout />} key="loggedIn">
                          <Route
                            path="/projects/:projectId"
                            element={<ProjectRouteWrapper />}
                            key="projectProtectedRoutes"
                          >
                            <Route
                              path="agents"
                              element={<ManageAgentsPage />}
                              key="manage-agents"
                            />
                            ,
                            <Route
                              path="agents/new"
                              element={<NewAgentPage />}
                              key="agents-new"
                            />
                            ,
                            <Route
                              path="agents/:agentId"
                              element={<NewAgentPage />}
                              key="agents-edit"
                            />
                            ,
                            <Route
                              path="questions"
                              element={<AskPage />}
                              key="home"
                            />
                            ,
                            <Route
                              path="conversations"
                              element={<AskPage />}
                              key="conversations-empty"
                            />
                            <Route
                              path="conversations/:conversationId"
                              element={<AskPage />}
                              key="conversations-id"
                            />
                            <Route
                              path="conversations/new"
                              element={<AskPage />}
                              key="conversations-new"
                            />
                            <Route
                              path="integrations/new"
                              element={<NewIntegrationPage />}
                              key="new-integration"
                            />
                            <Route
                              path="integrations"
                              element={<IntegrationsPage />}
                              key="integrations"
                            />
                            <Route
                              path="integrations/:uacProjectId"
                              element={<IntegrationPage />}
                              key="integrations"
                            />
                            <Route
                              path="questions/:questionId"
                              element={<QuestionPage />}
                              key="question"
                            />
                            <Route
                              path="questions/:questionId/document/:documentId"
                              element={<QuestionDocumentPage />}
                              key="question-document"
                            />
                            <Route
                              path="documents/:documentId"
                              element={<DocumentPage />}
                              key="documentPage"
                            />
                          </Route>
                          <Route
                            path="consent"
                            element={<ConsentPage />}
                            key="consent"
                          />
                          <Route
                            path="no-projects"
                            element={<NoProjectsPage />}
                            key="no-projects"
                          />
                          ,
                        </Route>
                      </Route>
                      <Route path="/" element={<IndexPage />} />
                      <Route
                        path="*"
                        element={<Navigate to="/" replace />}
                        key="catchall"
                      />
                    </Routes>
                  </FronteggProvider>
                }
              />
            </Route>
          </Routes>
        )}
        disabled={() => (
          <Routes>
            <Route
              element={<RouterSuspenseErrorTopLevelBoundary />}
              key="suspense"
            >
              <Route
                path="/businesses/:businessId/*"
                element={<RewriteBusinesses />}
              />
              <Route element={<ProtectedRoute />} key="protected">
                <Route element={<LoggedInLayout />} key="loggedIn">
                  {protectedRoutes}
                </Route>
              </Route>
              <Route element={<IsNotLoggedIn />} key="noLoggedIn">
                {isNotLoggedInRoutes}
              </Route>
              <Route element={<OpenLayout />} key="open">
                {openRoutes}
              </Route>
              <Route path="/" element={<IndexPage />} />
              <Route
                path="*"
                element={<Navigate to="/login" replace />}
                key="catchall"
              />
            </Route>
          </Routes>
        )}
        flagKey={(FlagKey) => FlagKey.Frontegg}
      />
    }
  />
);

const router = createBrowserRouter(createRoutesFromElements(routes));

export { router };
