import {
  useForm,
  DirectoryExplorer,
  PageSection,
  HeaderWithValue,
  Form,
  Stack,
  ThinkingAnimation,
} from "@trunk-tools/ui";
import { useUACProject, useUACProjectStatus } from "dataHooks";
import { SyncInfoStatus } from "./SyncInfoStatus.component";

interface Props {
  uacProjectId: string;
}

export const SyncInfo = ({ uacProjectId }: Props) => {
  const { uacDisplayName, uacFeatureSupport, uacProjectSyncConfig, isLoading } =
    useUACProject({
      uac_project_id: uacProjectId,
    });
  const { uacProjectStatus } = useUACProjectStatus({
    uac_project_id: uacProjectId,
  });

  if (isLoading) {
    return <ThinkingAnimation />;
  }

  return (
    <div>
      <PageSection header="Sync Info">
        <SyncInfoStatus
          displayName={uacDisplayName}
          documentStatusInfo={uacProjectStatus.documents_status}
        />
      </PageSection>
      <PageSection header="Connected Account">
        <HeaderWithValue header="name" value={uacDisplayName} />
        {uacFeatureSupport.rfi && (
          <HeaderWithValue
            header="RFIs"
            value={String(uacProjectSyncConfig?.rfis)}
          />
        )}
        {uacFeatureSupport.submittal && (
          <HeaderWithValue
            header="Submittals"
            value={String(uacProjectSyncConfig?.submittals)}
          />
        )}
        {uacFeatureSupport.specification_section && (
          <HeaderWithValue
            header="Specifications"
            value={String(uacProjectSyncConfig?.specs)}
          />
        )}
        {uacFeatureSupport.drawing && (
          <HeaderWithValue
            header="Drawings"
            value={String(uacProjectSyncConfig?.drawings)}
          />
        )}
        {uacFeatureSupport.meeting && (
          <HeaderWithValue
            header="Meetings"
            value={String(uacProjectSyncConfig?.meetings)}
          />
        )}
      </PageSection>
    </div>
  );
};

export const DataSets = ({ uacProjectId }: { uacProjectId: string }) => {
  const { uacFeatureSupport, uacProjectStatus } = useUACProject({
    uac_project_id: uacProjectId,
  });

  const { rfi, submittal, specification_section, drawing } = uacFeatureSupport;

  if (!rfi && !submittal && !specification_section && !drawing) {
    return (
      <div className="text-center text-gray-600">
        No Additional Datasets Supported
      </div>
    );
  }

  const drawingLabel =
    uacProjectStatus.uac_project.uac_account.source_system === "Procore"
      ? "Drawings"
      : "Sheets";

  return (
    <div>
      <Stack gap={6}>
        {uacFeatureSupport.rfi && (
          <Form.Fields.Switch name="RFISync" label="RFIs" />
        )}
        {uacFeatureSupport.submittal && (
          <Form.Fields.Switch name="submittalsSync" label="Submittals" />
        )}
        {uacFeatureSupport.specification_section && (
          <Form.Fields.Switch name="specSync" label="Specifications" />
        )}
        {uacFeatureSupport.drawing && (
          <Form.Fields.Switch name="drawingSync" label={drawingLabel} />
        )}
        {uacFeatureSupport.form && (
          <Form.Fields.Switch name="formSync" label="Forms" />
        )}
        {uacFeatureSupport.issue && (
          <Form.Fields.Switch name="issueSync" label="Issues" />
        )}
        {uacFeatureSupport.meeting && (
          <Form.Fields.Switch name="meetingSync" label="Meetings" />
        )}
      </Stack>
    </div>
  );
};

export const Folders = ({ uacProjectId }: { uacProjectId: string }) => {
  const { uacProjectSyncConfig, watchedFolderIds, isLoading } = useUACProject({
    uac_project_id: uacProjectId,
  });

  const availableFolders = uacProjectSyncConfig?.folders;

  const form = useForm<{ selectedFolders: string[] }>();

  const onChange = (selectedFolders: string[]) => {
    form.setFieldValue("selectedFolders", selectedFolders);
  };

  if (isLoading) {
    return <ThinkingAnimation />;
  }

  return (
    <DirectoryExplorer
      onChange={onChange}
      directory={availableFolders || []}
      initialSelectedIds={watchedFolderIds}
    />
  );
};
