import useSWR from "swr";
import { api } from "../helpers/APIClient";
import { useCurrentProject } from "../project.datahook";

export const useWorkatoJWTLink = (workatoConnectionId: number) => {
  const { currentProject } = useCurrentProject();

  const { data: jwtLink, isLoading } = useSWR(
    ["workatoJWTLink", currentProject.id, workatoConnectionId],
    () =>
      api.getWorkatoJWTTokenLink({
        projectId: currentProject.id,
        workatoConnectionId: String(workatoConnectionId),
      }),
    {
      suspense: true,
    },
  );

  return {
    jwtLink,
    isLoading,
  };
};
