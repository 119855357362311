import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SuspenseErrorBoundary } from "./SuspenseErrorBoundary.layout";

type Props = {
  children?: React.ReactNode;
};

export const OpenLayout = ({ children }: Props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="h-svh">
      <SuspenseErrorBoundary>{children}</SuspenseErrorBoundary>
    </div>
  );
};
