import useSWR from "swr";
import { api } from "../helpers/APIClient";
import { useCurrentProject } from "../project.datahook";

export const useAvailableWorkatoConnections = (
  workatoProjectId: string,
  workspaceFolderId: string,
) => {
  const { currentProject } = useCurrentProject();
  const { data: availableWorkatoConnections, isLoading } = useSWR(
    ["availableWorkatoConnections", workatoProjectId],
    () =>
      api.getAvailableWorkatoConnections({
        workato_project_id: workatoProjectId,
        project_id: currentProject.id,
        workato_folder_id: workspaceFolderId,
      }),
    {
      suspense: true,
    },
  );

  return {
    availableWorkatoConnections,
    isLoading,
  };
};
