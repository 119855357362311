import { useState } from "react";
import { api } from "../helpers/APIClient";
import { WorkatoConnectionPayloadType } from "@trunk-tools/txt-shared";
import { useCurrentProject } from "../project.datahook";

export const useCreateUACAccount = () => {
  const { currentProject } = useCurrentProject();

  const [isCreating, setIsCreating] = useState(false);

  const createUACAccount = async (
    workatoConnection: WorkatoConnectionPayloadType,
  ) => {
    setIsCreating(true);
    const response = await api.createUACAccount({
      project_id: currentProject.id,
      workato_connection: workatoConnection,
      source_system: "SharePoint_Online",
    });
    setIsCreating(false);
    return response;
  };

  return {
    createUACAccount,
    isCreating,
  };
};
