import { useEffect, useMemo, useState } from "react";
import { LoadingCircle } from "@trunk-tools/ui";
import { CorpusFilterBlock } from "./CorpusFilterBlock.component";
import {
  CorpusFilter,
  CorpusFilterFiltersSchema,
  CorpusFilterSchema,
} from "@trunk-tools/txt-shared";
import {
  useCorpusFilter,
  useCreateCorpusFilter,
  useUACProjects,
  useUpdateCorpusFilter,
  useUserAndSharedProjectCorpusFilters,
} from "dataHooks";
import {
  convertUACProjectsToTree,
  corpusFiltersAreDifferent,
} from "./CorpusFilters.utils";
import {
  LocalCorpusFilter,
  LocalCorpusFilterFilter,
} from "./CorpusFilters.types";
import { NewCorpusFilterModal } from "./CorpusFiltersComponents/NewCorpusFilterModal";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  AddRounded,
  CheckRounded,
  CloseRounded,
  MoreVertRounded,
  FilterListRounded,
} from "@mui/icons-material";

import { RenameFilterModal } from "./CorpusFiltersComponents/RenameFilterModal.component";
import { DeleteFilterModal } from "./CorpusFiltersComponents/DeleteFilterModal.component";
import { CorpusFiltersContext } from "./CorpusFilters.context";
import { UACProjectSelector } from "./CorpusFilterBlockComponents/UACProjectSelector.component";
import { CORPUS_FILTER_BLOCK_CLASSNAMES_ROOT } from "./CorpusFilterBlock.constants";
import { useResponsive } from "@/hooks/useResponsive";

const DEFAULT_CORPUS_FILTER = {
  id: "default",
  name: "Default",
  filters: [
    {
      _uuidKey: crypto.randomUUID(),
    },
  ],
};

const retrofitCorpusFilter = (corpusFilter: CorpusFilter) => {
  return {
    ...corpusFilter,
    filters: (corpusFilter.filters || []).map((filter) => ({
      ...filter,
      _uuidKey: crypto.randomUUID(),
    })),
  };
};

const CorpusFiltersPanel = ({
  initiallySelectedCorpusFilterId,
  onApply,
  onCancel,
}: {
  initiallySelectedCorpusFilterId?: CorpusFilter["id"];
  onApply: ({ corpusFilter }: { corpusFilter?: CorpusFilter }) => void;
  onCancel: () => void;
}) => {
  // ===========================
  // ========== STATE ==========
  // ===========================
  const [newCorpusFilterModalOpen, setNewCorpusFilterModalOpen] =
    useState(false);
  const [manageCorpusFilterAnchorEl, setManageCorpusFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  /**
   * All non-custom_temporary corpus filters, loaded from the API
   */
  const [corpusFilters, setCorpusFilters] = useState<
    Partial<LocalCorpusFilter>[]
  >([]);

  /**
   * Selected corpus filter state, including the original and selected corpus
   * filters. The orignal corpus filter is used to check if the selected
   * corpus filter has changed.
   */
  const [selectedCorpusFilterId, setSelectedCorpusFilterId] = useState<
    CorpusFilter["id"] | undefined
  >(initiallySelectedCorpusFilterId || DEFAULT_CORPUS_FILTER.id);
  const [originalCorpusFilter, setOriginalCorpusFilter] = useState<
    Partial<LocalCorpusFilter>
  >(DEFAULT_CORPUS_FILTER);
  const [selectedCorpusFilter, setSelectedCorpusFilter] = useState<
    Partial<LocalCorpusFilter>
  >(DEFAULT_CORPUS_FILTER);
  const [isCorpusFilterModified, setIsCorpusFilterModified] = useState(false);

  const {
    data: fetchedCorpusFilters,
    isLoading: isLoadingCorpusFiltersTemp,
    mutate: refetchCorpusFilters,
  } = useUserAndSharedProjectCorpusFilters();

  const {
    data: fetchedSelectedCorpusFilter,
    isLoading: isLoadingSelectedCorpusFilter,
  } = useCorpusFilter({ corpusFilterId: initiallySelectedCorpusFilterId });

  const isLoadingCorpusFilters =
    isLoadingCorpusFiltersTemp || isLoadingSelectedCorpusFilter;

  const { trigger: updateCorpusFilter, isMutating: isUpdatingCorpusFilter } =
    useUpdateCorpusFilter({
      onSuccess: (data) => {
        onApply({ corpusFilter: data });
      },
    });
  const { trigger: createCorpusFilter, isMutating: isCreatingCorpusFilter } =
    useCreateCorpusFilter({
      onSuccess: (data) => {
        onApply({ corpusFilter: data });
      },
    });

  const { uacProjects } = useUACProjects({
    lifecycle_statuses: ["Ready"],
  });

  /**
   * Retrofit the UAC projects into a tree structure to display the
   * UAC source system as a parent node and its UAC projects as
   * children.
   */
  const { availableUACProjectsByService, allUACProjectsByService } =
    useMemo(() => {
      if (!uacProjects) {
        return {
          availableUACProjectsByService: [],
          allUACProjectsByService: [],
        };
      }

      const remainingUACProjects = uacProjects.filter(
        (uacProject) =>
          !selectedCorpusFilter?.filters?.filter(
            (filter) => filter.document_source_system === uacProject.id,
          ).length,
      );

      return {
        availableUACProjectsByService: convertUACProjectsToTree({
          uacProjects: remainingUACProjects,
        }),
        allUACProjectsByService: convertUACProjectsToTree({
          uacProjects,
        }),
      };
    }, [uacProjects, selectedCorpusFilter]);

  // =======================================
  // ========== LIFECYCLE METHODS ==========
  // =======================================

  /**
   * Handle when the corpus filters are fetched from the API.
   */
  useEffect(() => {
    if (fetchedCorpusFilters) {
      /**
       * Retrofit all corpus filters with a temporary UUID so when the
       * filter blocks are rendered, the CorpusFilterBlock component gets
       * a unique key for each filter block, ensuring it re-renders when
       * the filter is changed.
       */
      const corpusFiltersToProcess = [...fetchedCorpusFilters];
      if (fetchedSelectedCorpusFilter?.temporary_custom) {
        corpusFiltersToProcess.unshift(fetchedSelectedCorpusFilter);
      }

      const retrofittedCorpusFilters = corpusFiltersToProcess.map(
        (corpusFilter) => retrofitCorpusFilter(corpusFilter),
      );

      setCorpusFilters([DEFAULT_CORPUS_FILTER, ...retrofittedCorpusFilters]);

      /**
       * Find the selected corpus filter within the list of all
       * fetched corpus filters.
       */
      const foundSelectedCorpusFilter = retrofittedCorpusFilters.find(
        (filter) => filter.id === selectedCorpusFilterId,
      );

      if (foundSelectedCorpusFilter) {
        setSelectedCorpusFilter(foundSelectedCorpusFilter);
        setOriginalCorpusFilter(foundSelectedCorpusFilter);
      }
    }
  }, [fetchedCorpusFilters, fetchedSelectedCorpusFilter]);

  /**
   * Memoize existing corpus filters for the <Select> menu.
   */
  const memoizedCorpusFiltersForSelect = useMemo(() => {
    return corpusFilters.map((corpusFilter) => ({
      value: corpusFilter.id!,
      label: corpusFilter.name!,
    }));
  }, [corpusFilters]);

  /**
   * Handle when the corpus filter is selected from the select menu.
   */
  const handleSelectCorpusFilter = (nextValue: CorpusFilter["id"]) => {
    if (nextValue) {
      const foundCorpusFilter = corpusFilters.find(
        (corpusFilter) => corpusFilter.id === nextValue,
      );

      if (foundCorpusFilter) {
        setOriginalCorpusFilter(foundCorpusFilter);
        setSelectedCorpusFilter(foundCorpusFilter);
        setSelectedCorpusFilterId(nextValue);
      }
    }
  };

  /**
   * Convenience to set `isCorpusFilterModified` to true if the filters have changed
   */
  useEffect(() => {
    setIsCorpusFilterModified(
      corpusFiltersAreDifferent({
        corpusFilterA: selectedCorpusFilter,
        corpusFilterB: originalCorpusFilter,
      }),
    );
  }, [selectedCorpusFilter, originalCorpusFilter]);

  /**
   * ====================================
   * ========== APPLY HANDLERS ==========
   * ====================================
   *
   * Note: the `onApply` callback is invoked after successful update or
   * creation of the corpus filter. See the `useCreateCorpusFilter` and
   * `useUpdateCorpusFilter` data hooks above.
   */
  const handleApply = () => {
    if (
      selectedCorpusFilterId === DEFAULT_CORPUS_FILTER.id &&
      !isCorpusFilterModified
    ) {
      onApply({ corpusFilter: undefined });
      return;
    }

    if (!isCorpusFilterModified) {
      onApply({ corpusFilter: CorpusFilterSchema.parse(selectedCorpusFilter) });
      return;
    }

    createCorpusFilter({
      name: "Custom",
      filters: CorpusFilterFiltersSchema.parse(selectedCorpusFilter.filters),
      visibility: "PERSONAL",
      temporary_custom: true,
    });
  };

  /**
   * The Save & Apply button is only available and enabled if:
   *
   * 1. An existing corpus filter is selected
   * 2. The corpus filter has been modified
   *
   * Thus only the `updateCorpusFilter` mutation is invoked.
   */
  const handleSaveAndApply = () => {
    const parsedSelectedCorpusFilter =
      CorpusFilterSchema.parse(selectedCorpusFilter);

    updateCorpusFilter(parsedSelectedCorpusFilter);
  };

  const handleManageClick = (event: React.MouseEvent<HTMLElement>) => {
    setManageCorpusFilterAnchorEl(event.currentTarget);
  };

  const handleManageClose = () => {
    setManageCorpusFilterAnchorEl(null);
  };

  const handleCorpusFilterRenameClick = () => {
    handleManageClose();
    setRenameModalOpen(true);
  };

  const handleCorpusFilterDeleteClick = () => {
    handleManageClose();
    setDeleteModalOpen(true);
  };

  const handleFilterBlockChange = ({
    filters,
  }: {
    filters: LocalCorpusFilterFilter;
  }) => {
    /**
     * Find the filter block based on the _uuidKey and update the filters
     */
    setSelectedCorpusFilter((currentCorpusFilter) => {
      return {
        ...currentCorpusFilter,
        filters: currentCorpusFilter.filters?.map((f) =>
          f._uuidKey === filters._uuidKey ? { ...f, ...filters } : f,
        ),
      };
    });
  };

  const handleFilterBlockDelete = ({
    filterBlockId,
  }: {
    filterBlockId: string;
  }) => {
    setSelectedCorpusFilter((currentCorpusFilter) => {
      const filters = currentCorpusFilter.filters || [];
      // If only one filter block remaining, clear its contents rather than removing it
      if (filters.length <= 1) {
        return {
          ...currentCorpusFilter,
          filters: [
            {
              _uuidKey: filterBlockId,
            },
          ],
        };
      }

      // Otherwise remove the filter block
      return {
        ...currentCorpusFilter,
        filters: filters.filter((f) => f._uuidKey !== filterBlockId),
      };
    });
  };

  /**
   * Convenience used to conditionally render the UACProjectSelector component
   * so the user can select another integration to start another filter block.
   * It will render if:
   *
   * 1. At least one filter block has a selected UAC project
   * 2. There is at least one UAC project remaining to select from
   */
  const showUACProjectSelector = useMemo(() => {
    const atLeastOneFilterBlockHasSelectedUACProject =
      selectedCorpusFilter?.filters?.some(
        (filter) => filter.document_source_system,
      );

    const atLeastOneUACProjectRemaining =
      selectedCorpusFilter?.filters?.length !== uacProjects?.length;

    return (
      atLeastOneFilterBlockHasSelectedUACProject &&
      atLeastOneUACProjectRemaining
    );
  }, [selectedCorpusFilter, uacProjects]);

  const { isDesktop, isMobile } = useResponsive();

  return (
    <>
      <DialogContent>
        <div className="flex flex-col gap-6">
          {isLoadingCorpusFilters && <LoadingCircle />}
          <div className="flex flex-row flex-nowrap items-center">
            <div className="min-w-0 flex-1">
              <Select
                onChange={(event) => {
                  handleSelectCorpusFilter(event.target.value);
                }}
                fullWidth
                value={selectedCorpusFilterId}
              >
                {memoizedCorpusFiltersForSelect.map((corpusFilter) => (
                  <MenuItem key={corpusFilter.value} value={corpusFilter.value}>
                    {corpusFilter.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex flex-row items-center shrink-0">
              {selectedCorpusFilterId !== DEFAULT_CORPUS_FILTER.id && (
                <>
                  <IconButton
                    onClick={handleManageClick}
                    size="medium"
                    disabled={isLoadingCorpusFilters}
                    className="text-white ml-2"
                    sx={{ padding: 0 }}
                  >
                    <MoreVertRounded className="w-8 h-8" />
                  </IconButton>
                  <Menu
                    anchorEl={manageCorpusFilterAnchorEl}
                    open={Boolean(manageCorpusFilterAnchorEl)}
                    onClose={handleManageClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem onClick={handleCorpusFilterRenameClick}>
                      Rename...
                    </MenuItem>
                    <MenuItem onClick={handleCorpusFilterDeleteClick}>
                      Delete...
                    </MenuItem>
                  </Menu>
                </>
              )}
              <div className="ml-4">
                <Button
                  startIcon={<AddRounded />}
                  color="success"
                  variant="contained"
                  onClick={() => setNewCorpusFilterModalOpen(true)}
                  disabled={isLoadingCorpusFilters}
                >
                  New
                </Button>
              </div>
            </div>
          </div>
          <CorpusFiltersContext.Provider
            value={{
              allUACProjects: uacProjects,
              allUACProjectsByService,
              availableUACProjectsByService,
              onFilterBlockChange: handleFilterBlockChange,
              onFilterBlockDelete: handleFilterBlockDelete,
            }}
          >
            <div className="grow flex flex-col gap-4 desktop:gap-8">
              {selectedCorpusFilter?.filters?.map((filterBlock) => (
                <CorpusFilterBlock
                  key={filterBlock._uuidKey}
                  filters={filterBlock}
                  isOnlyFilterBlock={
                    selectedCorpusFilter?.filters?.length === 1
                  }
                />
              ))}
            </div>
            {showUACProjectSelector && (
              <div className={CORPUS_FILTER_BLOCK_CLASSNAMES_ROOT}>
                <Typography variant="body1" className="mb-3 uppercase">
                  Add Filter Block
                </Typography>
                <UACProjectSelector
                  label="Select Integration"
                  showFilteredUACProjectsOnly
                  onSelect={({ uacProjectId }) => {
                    setSelectedCorpusFilter((currentCorpusFilter) => {
                      return {
                        ...currentCorpusFilter,
                        filters: [
                          ...(currentCorpusFilter.filters || []),
                          {
                            _uuidKey: crypto.randomUUID(),
                            document_source_system: uacProjectId,
                          },
                        ],
                      };
                    });
                  }}
                />
              </div>
            )}
          </CorpusFiltersContext.Provider>
        </div>
      </DialogContent>
      <DialogActions className="justify-between">
        <Button
          color="secondary"
          size="large"
          startIcon={<CloseRounded />}
          onClick={onCancel}
          className="hidden desktop:flex"
        >
          Cancel
        </Button>
        <div
          className="flex flex-row items-center w-full desktop:w-auto gap-3 desktop:gap-4"
          // No idea why MUI has a margin left on divs in DialogActions
          style={{ marginLeft: 0 }}
        >
          <div className="grow">
            <LoadingButton
              color="secondary"
              size={isDesktop ? "large" : "medium"}
              fullWidth={isMobile}
              startIcon={<FilterListRounded />}
              onClick={handleApply}
              disabled={isLoadingCorpusFilters}
              loading={isCreatingCorpusFilter}
              data-pendo-id="corpus_filter_apply"
            >
              Apply
            </LoadingButton>
          </div>
          {selectedCorpusFilterId !== DEFAULT_CORPUS_FILTER.id && (
            <div className="w-2/3 desktop:w-auto">
              <LoadingButton
                color="success"
                size={isDesktop ? "large" : "medium"}
                fullWidth={isMobile}
                startIcon={<CheckRounded />}
                onClick={handleSaveAndApply}
                disabled={isLoadingCorpusFilters || !isCorpusFilterModified}
                loading={isUpdatingCorpusFilter}
                data-pendo-id="corpus_filter_save_and_apply"
              >
                Save &amp; Apply
              </LoadingButton>
            </div>
          )}
        </div>
      </DialogActions>
      {newCorpusFilterModalOpen && (
        <NewCorpusFilterModal
          onClose={() => setNewCorpusFilterModalOpen(false)}
          filters={selectedCorpusFilter.filters || []}
          onFilterCreated={({ filter }) => {
            const retrofittedFilter = retrofitCorpusFilter(filter);
            setSelectedCorpusFilter(retrofittedFilter);
            setOriginalCorpusFilter(retrofittedFilter);
            setSelectedCorpusFilterId(filter.id);
            refetchCorpusFilters();
            setNewCorpusFilterModalOpen(false);
          }}
        />
      )}
      {renameModalOpen && (
        <RenameFilterModal
          onClose={() => setRenameModalOpen(false)}
          onSuccess={() => {
            refetchCorpusFilters();
            setRenameModalOpen(false);
          }}
          filter={CorpusFilterSchema.parse(selectedCorpusFilter)}
        />
      )}
      {deleteModalOpen && (
        <DeleteFilterModal
          onClose={() => setDeleteModalOpen(false)}
          onSuccess={() => {
            setSelectedCorpusFilterId(DEFAULT_CORPUS_FILTER.id);
            setSelectedCorpusFilter(DEFAULT_CORPUS_FILTER);
            setOriginalCorpusFilter(DEFAULT_CORPUS_FILTER);
            refetchCorpusFilters();
            setDeleteModalOpen(false);
          }}
          corpusFilterId={selectedCorpusFilter.id!}
          corpusFilterName={selectedCorpusFilter.name!}
        />
      )}
    </>
  );
};

export const CorpusFilters = ({
  onApply,
  onDialogClose,
  initiallySelectedCorpusFilterId,
}: {
  onApply: ({ corpusFilter }: { corpusFilter?: CorpusFilter }) => void;
  onDialogClose: () => void;
  initiallySelectedCorpusFilterId: CorpusFilter["id"] | undefined;
}) => {
  return (
    <>
      <Dialog open maxWidth="md">
        <DialogTitle>Filter</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onDialogClose}
          size="large"
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
            padding: 0,
          }}
        >
          <CloseRounded className="w-10 h-10" />
        </IconButton>
        <CorpusFiltersPanel
          initiallySelectedCorpusFilterId={initiallySelectedCorpusFilterId}
          onCancel={onDialogClose}
          onApply={onApply}
        />
      </Dialog>
    </>
  );
};
