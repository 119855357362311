import { useContext, useMemo, useState } from "react";
import { CorpusFiltersContext } from "../CorpusFilters.context";
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  DialogActions,
  Skeleton,
  Tooltip,
  Alert,
} from "@mui/material";
import {
  IoCloseOutline,
  IoCheckmarkOutline,
  IoDocumentTextOutline,
  IoFolderOutline,
} from "react-icons/io5";
import { LocalCorpusFilterFilter } from "../CorpusFilters.types";
import { useUACProject } from "dataHooks";
import { RichTreeView } from "@mui/x-tree-view";
import { UACProjectSyncConfigResponseType } from "@trunk-tools/txt-shared";
import { TextInput } from "@trunk-tools/ui";
import { useDebounceValue } from "usehooks-ts";

const ICON_CLASSNAME = "w-6 h-6";

const SelectedFolderOrFile = ({
  onRemove,
  type,
  item,
}: {
  onRemove: () => void;
  type: "folder" | "file";
  item: {
    id: string;
    label: string;
  };
}) => {
  return (
    <div className="flex flex-row gap-x-2 items-center justify-between rounded-sm bg-white text-black py-2 px-3">
      <div className="flex flex-row gap-x-2 items-center truncate">
        {type === "folder" ? (
          <IoFolderOutline className="w-4 h-4" />
        ) : (
          <IoDocumentTextOutline className="w-4 h-4" />
        )}
        {item.label}
      </div>
      <IconButton onClick={onRemove} size="small" className="p-0">
        <IoCloseOutline className="w-6 h-6" />
      </IconButton>
    </div>
  );
};

type TreeItemWithType = {
  id: string;
  label: string;
  type: "folder" | "file";
  children: TreeItemWithType[] | null;
};

const TreeLoadingSkeleton = () => {
  return (
    <div className="flex flex-row gap-x-2 iems-center">
      <Skeleton variant="rectangular" width={24} height={24} />
      <Skeleton variant="rectangular" width="100%" height={24} />
    </div>
  );
};

export const CorpusFilterFoldersAndFiles = ({
  filters,
  uacProjectLabel,
  onClose,
}: {
  filters: LocalCorpusFilterFilter;
  uacProjectLabel: string;
  onClose: () => void;
}) => {
  const [selectedFolderIds, setSelectedFolderIds] = useState<string[]>(
    filters.folder_ids || [],
  );
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(
    filters.document_ids || [],
  );
  const { onFilterBlockChange } = useContext(CorpusFiltersContext);

  const { uacProjectSyncConfig, isLoading: isUACProjectSyncConfigLoading } =
    useUACProject({
      uac_project_id: filters.document_source_system!,
    });

  const availableFoldersAndFiles = uacProjectSyncConfig?.folders;

  const retrofittedFoldersAndFilesTree: TreeItemWithType[] = useMemo(() => {
    const transformFolder = ({
      item,
    }: {
      item: UACProjectSyncConfigResponseType["sync_config"]["folders"][number];
    }) => {
      return {
        id: item.id,
        label: item.name,
        type: "folder",
        children: [
          ...item.files.map((file) => transformFile({ item: file })),
          ...item.child_folders.map((childFolder) =>
            transformFolder({ item: childFolder }),
          ),
        ],
      };
    };

    const transformFile = ({
      item,
    }: {
      item: UACProjectSyncConfigResponseType["sync_config"]["folders"][number]["files"][number];
    }) => {
      return {
        id: item.document_id,
        label: item.name,
        type: "file",
      };
    };

    return (availableFoldersAndFiles || []).map((folder) =>
      transformFolder({ item: folder }),
    );
  }, [availableFoldersAndFiles]);

  /**
   * Flatten the folders and files tree into a single array of items.
   * This is used to make it easier to find selected and searched
   * items for rendering.
   */
  const flattenedFoldersAndFiles = useMemo(() => {
    const flattenItem = (item) => [
      item,
      ...(item.children?.flatMap(flattenItem) || []),
    ];

    return retrofittedFoldersAndFilesTree.flatMap(flattenItem).map((item) => ({
      id: item.id,
      label: item.label,
      type: item.type,
    }));
  }, [retrofittedFoldersAndFilesTree]);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 200);
  const foldersAndFilesSearchResults = useMemo(() => {
    const lowerCaseSearchTerm = debouncedSearchTerm.toLowerCase();
    return flattenedFoldersAndFiles.filter((item) => {
      return item.label.toLowerCase().includes(lowerCaseSearchTerm);
    });
  }, [flattenedFoldersAndFiles, debouncedSearchTerm]);

  /**
   * Determine if the selected folder and document ids have changed
   * from the initial set provided by the `filters` prop.
   */
  const hasChanges = useMemo(() => {
    if (
      selectedFolderIds.length !== (filters.folder_ids || []).length ||
      selectedDocumentIds.length !== (filters.document_ids || []).length
    ) {
      return true;
    }

    if (
      selectedFolderIds.some((id) => !filters.folder_ids?.includes(id)) ||
      selectedDocumentIds.some((id) => !filters.document_ids?.includes(id))
    ) {
      return true;
    }

    return false;
  }, [
    selectedFolderIds,
    selectedDocumentIds,
    filters.folder_ids,
    filters.document_ids,
  ]);

  const handleApply = () => {
    onFilterBlockChange({
      filters: {
        ...filters,
        folder_ids: selectedFolderIds,
        document_ids: selectedDocumentIds,
      },
    });
    onClose();
  };

  const hasFoldersOrFiles = Boolean(retrofittedFoldersAndFilesTree?.length);
  const hasNoFoldersOrFiles =
    !isUACProjectSyncConfigLoading && !hasFoldersOrFiles;
  const hasNoSearchResults =
    debouncedSearchTerm && !foldersAndFilesSearchResults?.length;

  return (
    <Dialog open maxWidth="md">
      <DialogTitle>
        Select Folders &amp; Files
        <span className="hidden desktop:inline">
          &nbsp;from {uacProjectLabel}
        </span>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
          padding: 0,
        }}
      >
        <IoCloseOutline className="w-10 h-10" />
      </IconButton>
      <DialogContent>
        <div className="flex flex-row gap-x-7 w-full justify-between">
          <div className="flex flex-col w-full desktop:w-[55%]">
            <Typography variant="body2" className="uppercase mb-3">
              Search
            </Typography>
            <TextInput
              rounded
              name="corpus-filter-folders-and-files-search"
              placeholder="Search..."
              value={searchTerm}
              isDisabled={isUACProjectSyncConfigLoading || hasNoFoldersOrFiles}
              onChange={(nextValue) => {
                setSearchTerm(nextValue);
              }}
            />
            <div className="mt-4 desktop:min-h-[280px] desktop:max-h-[280px] overflow-y-auto">
              {isUACProjectSyncConfigLoading && (
                <div className="flex flex-col gap-y-2">
                  <TreeLoadingSkeleton />
                  <TreeLoadingSkeleton />
                  <TreeLoadingSkeleton />
                </div>
              )}
              {hasNoFoldersOrFiles && (
                <Alert severity="warning">
                  No folders or files available for selection.
                </Alert>
              )}
              {hasNoSearchResults && (
                <Alert severity="info">
                  No folders or files found for "{debouncedSearchTerm}".
                </Alert>
              )}
              {hasFoldersOrFiles && (
                <RichTreeView
                  items={
                    debouncedSearchTerm?.length
                      ? foldersAndFilesSearchResults
                      : retrofittedFoldersAndFilesTree
                  }
                  selectedItems={[
                    ...(selectedFolderIds || []),
                    ...(selectedDocumentIds || []),
                  ]}
                  onSelectedItemsChange={(_event, itemIds) => {
                    /**
                     * Find the itemIds in the flattenedFoldersAndFiles array
                     * and update the filters accordingly
                     */
                    const selectedItems = itemIds.map((id) =>
                      flattenedFoldersAndFiles.find((item) => item.id === id),
                    );

                    const folderIds = selectedItems
                      .filter((item) => item?.type === "folder")
                      .map((item) => item?.id);
                    const documentIds = selectedItems
                      .filter((item) => item?.type === "file")
                      .map((item) => item?.id);

                    setSelectedFolderIds(folderIds);
                    setSelectedDocumentIds(documentIds);
                  }}
                  multiSelect
                  checkboxSelection
                />
              )}
            </div>
          </div>
          <div className="hidden desktop:flex desktop:flex-col desktop:w-[40%] bg-[#0F151A7A] border border-[#0D1217] py-5 px-6 rounded-md">
            <Typography fontWeight={500} className="uppercase">
              Selected Folders &amp; Files
            </Typography>
            <div className="flex flex-col gap-y-2 mt-3">
              {!selectedFolderIds?.length && !selectedDocumentIds?.length && (
                <Typography className="text-gray-400">
                  No folders or files selected
                </Typography>
              )}
              {selectedFolderIds.map((folderId) => {
                const folder = flattenedFoldersAndFiles.find(
                  (item) => item.id === folderId,
                );

                if (!folder) {
                  return null;
                }

                return (
                  <SelectedFolderOrFile
                    key={`folder_${folderId}`}
                    type="folder"
                    item={folder}
                    onRemove={() => {
                      setSelectedFolderIds(
                        selectedFolderIds?.filter((id) => id !== folderId),
                      );
                    }}
                  />
                );
              })}
              {selectedDocumentIds.map((documentId) => {
                const document = flattenedFoldersAndFiles.find(
                  (item) => item.id === documentId,
                );

                if (!document) {
                  return null;
                }

                return (
                  <SelectedFolderOrFile
                    key={`document_${documentId}`}
                    type="file"
                    item={document}
                    onRemove={() => {
                      setSelectedDocumentIds(
                        selectedDocumentIds?.filter((id) => id !== documentId),
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="flex justify-between">
        <div>
          <Button
            color="secondary"
            size="large"
            startIcon={<IoCloseOutline className={ICON_CLASSNAME} />}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
        <Tooltip
          title={hasChanges ? "" : "No changes to apply"}
          placement="top"
          arrow
        >
          <div>
            <Button
              color="success"
              size="large"
              disabled={!hasChanges}
              startIcon={<IoCheckmarkOutline className={ICON_CLASSNAME} />}
              onClick={handleApply}
            >
              Apply
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
