import { useState } from "react";
import { TextInput } from "@trunk-tools/ui";
import { useCreateCorpusFilter } from "dataHooks";
import {
  CorpusFilter,
  CorpusFilterFiltersSchema,
} from "@trunk-tools/txt-shared";
import { LocalCorpusFilter } from "../CorpusFilters.types";
import { IoCheckmarkOutline } from "react-icons/io5";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const NewCorpusFilterModal = ({
  onClose,
  onFilterCreated,
  filters,
}: {
  onClose: () => void;
  onFilterCreated: ({ filter }: { filter: CorpusFilter }) => void;
  filters: LocalCorpusFilter["filters"];
}) => {
  const [name, setName] = useState("");

  const { trigger: createCorpusFilter, isMutating } = useCreateCorpusFilter({
    onSuccess: (data) => {
      onFilterCreated({ filter: data });
      setName("");
    },
  });

  const handleSubmit = () => {
    createCorpusFilter({
      name,
      filters: CorpusFilterFiltersSchema.parse(filters),
      visibility: "PERSONAL",
      temporary_custom: false,
    });
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Custom Filter</DialogTitle>
      <DialogContent>
        <TextInput
          rounded
          name="new-corpus-filter-name"
          size="lg"
          placeholder="Type a name for this filter..."
          value={name}
          onChange={(nextValue) => {
            setName(nextValue);
          }}
        />
        <div className="flex justify-end mt-6">
          <div>
            <LoadingButton
              color="success"
              size="large"
              startIcon={<IoCheckmarkOutline className="w-6 h-6" />}
              onClick={handleSubmit}
              disabled={!name}
              loading={isMutating}
              data-pendo-id="corpus_filter_create"
            >
              Save Filter
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
