import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";
import { useConversation } from "@/dataHooks/conversations.dataHook";
import { RefContext, RefProvider } from "@/providers/ref.provider";
import { ConversationItemMessage, ShortenedTileList } from "@trunk-tools/ui";
import { useRef, useEffect, useContext, useState } from "react";
import { ScheduleAgentOverviewConversationItem } from "./ScheduleAgentResponses/ScheduleAgentOverviewConversationItem.component";
import { ScheduleAgentSuggestedFollowupsItem } from "./ScheduleAgentResponses/ScheduleAgentSuggestedFollowupsItem.component";
import { useInterval } from "@/hooks/useInterval";
import { ThinkingMessageContent } from "./Thinking.component";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { useParams } from "react-router-dom";
import { useDocuments } from "@/dataHooks/documents.dataHook";
import { DocumentTile } from "./DocumentTile.component";
import { DownloadStringAsCSVButton } from "../DownloadStringAsCsv.component";
import { QuestionBankAgentSummary } from "./QuestionBankAgentSummary.component";
import { UsefulnessVote } from "./UsefulnessVote.component";
import { QuestionAndAnswer } from "./ConversationItems/QuestionAndAnswerComponents/QuestionAndAnswer.component";

const ScheduledAgentFollowupDocuments = ({
  item,
  isLastItem,
  partOfPreviousItem,
}: {
  isLastItem: boolean;
  partOfPreviousItem: boolean;
  item: {
    id: string;
    task_name: string;
    created_at: Date;
    document_ids: string[];
    status: string;
  };
}) => {
  const headerContent = (
    <div className="flex flex-col gap-y-2">
      <div>
        {item.created_at.toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </div>
      <div className="flex flex-row items-center text-lg gap-x-1 font-poppins">
        <p>
          <span className="font-bold">Documents: </span>
          {`${item.task_name}`}
        </p>
      </div>
    </div>
  );
  let content = (
    <ThinkingMessageContent
      id={`schedule-agent-documents-retrieval-thinking-${item.task_name}`}
      text=""
    />
  );
  if (item.status !== "PENDING" && item.document_ids.length > 0) {
    content = <ScheduledAgentFollowupDocumentsCard item={item} />;
  } else if (item.status !== "PENDING" && item.document_ids.length === 0) {
    content = <p>No documents found.</p>;
  }

  return (
    <ConversationItemMessage
      variant="green"
      withoutAvatar={partOfPreviousItem}
      avatarProps={
        !partOfPreviousItem
          ? {
              variant: "v2yellowResponsive",
              img: ttMessageAvatarLogo,
              name: "TrunkText",
            }
          : undefined
      }
      collapsible
      highlightOnHoverWhileOpen
      headerContent={headerContent}
      collapsedHeaderContent={headerContent}
      partOfPreviousItem={partOfPreviousItem}
      isLastItem={isLastItem}
    >
      {content}
    </ConversationItemMessage>
  );
};

const ScheduledAgentFollowupDocumentsCard = ({
  item,
}: {
  item: {
    id: string;
    task_name: string;
    created_at: Date;
    document_ids: string[];
    status: string;
  };
}) => {
  const idNavigate = useIdNavigate();
  const { conversationId } = useParams<{ conversationId: string }>();
  const title = `Documents: ${item.task_name}`;
  const { documents } = useDocuments({ documentIds: item.document_ids });

  return (
    <>
      <ShortenedTileList
        tiles={documents.map(({ id }) => (
          <DocumentTile
            key={id}
            showSubContent={false}
            documentId={id}
            onClick={() => {
              idNavigate(`/documents/${id}?conversationId=${conversationId}`);
            }}
          />
        ))}
        maxTilesToDisplay={3}
        popupTitle={title}
        viewButtonText="show more"
      />
      <div className="w-full mt-9 mb-7 h-[2px] shadow-separator"></div>

      <div className={`w-full desktop:max-h-full desktop:h-[50px]`}>
        <div
          className={`flex flex-col-reverse gap-y-6 gap-x-2 items-center justify-between w-full desktop:flex desktop:flex-row`}
        >
          <div
            className="flex flex-row items-center desktop:w-[180px]"
            data-pendo-id="conversation-download-csv"
          >
            <DownloadStringAsCSVButton
              filename={`${item.task_name} documents`}
              csvString={`name,source,link\n${documents
                .map(
                  ({ name, source, url }) =>
                    `${name},${source ? source : "unknown"},${url}`,
                )
                .join("\n")}`}
            >
              <p className="uppercase">download .csv</p>
            </DownloadStringAsCSVButton>
          </div>

          <div className="flex flex-row gap-x-2 items-center justify-between">
            <div className="uppercase text-xs">
              was this information helpful?"
            </div>
            <UsefulnessVote
              itemId={item.id}
              itemType={"SCHEDULE_TASK_DOCUMENTS"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ScheduleAgentDocumentRetrievalItem = ({
  i,
  conversation,
  item,
}: {
  i: number;
  conversation: NonNullable<ReturnType<typeof useConversation>["conversation"]>;
  item: {
    id: string;
    task_name: string;
    created_at: Date;
    document_ids: string[];
    status: string;
  };
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { setRefs } = useContext(RefContext);
  useEffect(() => {
    if (ref.current) {
      setRefs(item.id, ref);
    }
  }, [ref]);

  return (
    <div id={item.id} ref={ref}>
      <ScheduledAgentFollowupDocuments
        item={item}
        isLastItem={
          i === conversation.conversationItems.length - 1 ||
          conversation.conversationItems[i + 1].type !==
            "schedule_agent_document_retrieval"
        }
        partOfPreviousItem={
          (i !== 0 &&
            conversation.conversationItems[i - 1].type ===
              "schedule_agent_document_retrieval") ||
          conversation.conversationItems[i - 1].type ===
            "schedule_agent_overview"
        }
      />
    </div>
  );
};

const ConversationQuestionsInner = ({
  conversationId,
  updateQuestionText,
}: {
  conversationId: string;
  updateQuestionText: (text: string) => void;
}) => {
  const { conversation, refetchConversation } = useConversation({
    conversationId,
  }) as {
    conversation: NonNullable<
      ReturnType<typeof useConversation>["conversation"]
    >;
    refetchConversation: () => void;
  };
  const messageContainerDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageContainerDivRef.current) {
      messageContainerDivRef.current.scrollTop =
        messageContainerDivRef.current.scrollHeight;
    }
  }, [conversation.conversationItems.length]);

  const questions = conversation.conversationItems.filter(
    (c) => c.type === "question",
  );

  const questionBankAgentQuestions = conversation.conversationItems.filter(
    (c) => c.type === "question" && c.question.originator === "AGENT",
  );

  const questionsArePartOfQuestionBankAgent =
    questionBankAgentQuestions.length > 0;

  const lastQuestion: (typeof questions)[number] | undefined =
    questions[questions.length - 1];
  let shouldRefetchConversations = false;

  if (lastQuestion) {
    if (lastQuestion.question.state !== "ANSWERED") {
      shouldRefetchConversations = true;
    }
  }

  const scheduleAgentOverview = conversation.conversationItems.filter(
    (c) => c.type === "schedule_agent_overview",
  );
  if (
    scheduleAgentOverview.length > 0 &&
    scheduleAgentOverview[0].status === "PENDING"
  ) {
    shouldRefetchConversations = true;
  }

  const scheduleAgentDocumentsRequests = conversation.conversationItems.filter(
    (c) => c.type === "schedule_agent_document_retrieval",
  );

  const waitingForDocuments = scheduleAgentDocumentsRequests.some(
    (c) => c.status === "PENDING",
  );

  if (waitingForDocuments) {
    shouldRefetchConversations = true;
  }

  useInterval(refetchConversation, shouldRefetchConversations ? 2000 : null);

  const [showLatestQuestionAnswer, setShowLatestQuestionAnswer] =
    useState(false);
  const [isLatestQuestionAnswerRendered, setIsLatestQuestionAnswerRendered] =
    useState(false);

  useEffect(() => {
    setShowLatestQuestionAnswer(false);
    setIsLatestQuestionAnswerRendered(false);
  }, [conversation.id, conversation.conversationItems.length]);

  useEffect(() => {
    if (conversation.conversationItems.length === 0) return;

    const lastConversationItem =
      conversation.conversationItems[conversation.conversationItems.length - 1];

    let showTimeoutId: NodeJS.Timeout | undefined = undefined;
    let isRenderedTimeoutId: NodeJS.Timeout | undefined = undefined;

    if (lastConversationItem.type === "question") {
      if (
        new Date().getTime() -
          new Date(lastConversationItem.question.created_at).getTime() <
        800
      ) {
        setShowLatestQuestionAnswer(false);
        setIsLatestQuestionAnswerRendered(false);

        showTimeoutId = setTimeout(() => {
          setShowLatestQuestionAnswer(true);

          isRenderedTimeoutId = setTimeout(() => {
            setIsLatestQuestionAnswerRendered(true);
          }, 10);
        }, 800);
      } else {
        setShowLatestQuestionAnswer(true);
        setIsLatestQuestionAnswerRendered(true);
      }
    }

    return () => {
      if (showTimeoutId) clearTimeout(showTimeoutId);
      if (isRenderedTimeoutId) clearTimeout(isRenderedTimeoutId);
    };
  }, [conversation.id, conversation.conversationItems.length]);

  return (
    <div
      ref={messageContainerDivRef}
      className="h-full p-4 flex flex-col overflow-scroll no-scrollbar"
    >
      {/**
       * Typically, you would use justify-end to put all of the flex items at the
       * end of a flex container. However, justify-end makes it so that the container
       * is non-scrollable no matter what. So, we stick a "dummy" div using mt-auto
       * to fill up any top space of the container when there's not many conversation
       * items. Then, when there's multiple conversation items, the mt-auto will not use
       * any more space and the container will be scrollable.
       * */}
      <div className="mt-auto"></div>
      {questionsArePartOfQuestionBankAgent && (
        <QuestionBankAgentSummary
          ids={conversation.conversationItems
            .map((item) => {
              if ("question" in item) {
                return item.question.question_bank_agent_question[0].id;
              }
              return undefined;
            })
            .filter((id) => id !== undefined)}
          questions={questionBankAgentQuestions.filter(
            (q) => q.type === "question",
          )}
        />
      )}
      {conversation.conversationItems.map((item, i) => {
        switch (item.type) {
          case "question": {
            if (item.question.originator === "AGENT") {
              return;
            }

            return (
              <QuestionAndAnswer
                key={item.question.id}
                condensedQuestionAndAnswerProps={{
                  indexInConversationItems: i,
                  conversationItemsLength:
                    conversation.conversationItems.length,
                  showLatestQuestionAnswer,
                  isLatestQuestionAnswerRendered,
                  item,
                }}
                questionAndSeparateRelevantDocumentsProps={{
                  indexInConversationItems: i,
                  conversationItemsLength:
                    conversation.conversationItems.length,
                  showLatestQuestionAnswer,
                  isLatestQuestionAnswerRendered,
                  item,
                }}
              />
            );
          }
          case "schedule_agent_overview":
            return (
              <div key={`${conversation.id}_${item.schedule_agent_output_id}`}>
                <ScheduleAgentOverviewConversationItem
                  item={item}
                  conversationId={conversation.id}
                  onRefetch={refetchConversation}
                />
                {item.suggested_followup_questions.length > 0 && (
                  <ScheduleAgentSuggestedFollowupsItem
                    item={item}
                    updateQuestionText={updateQuestionText}
                    isLastItem={
                      i === conversation.conversationItems.length - 1 ||
                      conversation.conversationItems[i + 1].type !==
                        "schedule_agent_document_retrieval"
                    }
                  />
                )}
              </div>
            );
          case "schedule_agent_document_retrieval":
            return (
              <ScheduleAgentDocumentRetrievalItem
                key={`${item.id}_${i}`}
                i={i}
                conversation={conversation}
                item={item}
              />
            );
        }
      })}
    </div>
  );
};

export const ConversationQuestions = ({
  conversationId,
  updateQuestionText,
}: {
  conversationId: string;
  updateQuestionText: (text: string) => void;
}) => {
  return (
    <RefProvider>
      <ConversationQuestionsInner
        conversationId={conversationId}
        updateQuestionText={updateQuestionText}
      />
    </RefProvider>
  );
};
