import { useAvailableWorkatoFolders } from "@/dataHooks/workato/available-workato-folders.dataHook";
import { useCreateWorkatoFolder } from "@/dataHooks/workato/create-workato-folder.dataHook";
import {
  WorkatoProjectResponseType,
  WorkatoCreatedFolderType,
} from "@trunk-tools/txt-shared";
import { Form, Text } from "@trunk-tools/ui";

interface SelectWorkspaceFolderProps {
  setSelectedWorkspaceFolder: (workatoFolder: WorkatoCreatedFolderType) => void;
  selectedWorkspaceProject: WorkatoProjectResponseType;
}

export const SelectWorkspaceFolder: React.FC<SelectWorkspaceFolderProps> = ({
  setSelectedWorkspaceFolder,
  selectedWorkspaceProject,
}) => {
  const { workatoFolders, isLoading } = useAvailableWorkatoFolders(
    selectedWorkspaceProject.id,
  );
  const { createWorkatoFolder, isCreating } = useCreateWorkatoFolder(
    selectedWorkspaceProject.id,
  );

  return (
    <div>
      <Form.Provider
        validators={{
          selectedWorkatoFolder: Form.validators.required,
        }}
        initialValues={{}}
        onSubmit={async (formFields: Record<string, string>) => {
          const selectedWorkatoFolder = workatoFolders.workato_folders.find(
            (workatoFolder: { id: number }) =>
              workatoFolder.id === Number(formFields.selectedWorkatoFolder),
          );

          if (!selectedWorkatoFolder) {
            return;
          }

          const createdFolder = await createWorkatoFolder({
            workato_id: selectedWorkatoFolder.id,
            name: selectedWorkatoFolder.name,
          });

          setSelectedWorkspaceFolder(createdFolder);
        }}
      >
        <>
          <Text.Header className="mb-3">
            Select A Project Workspace Folder
          </Text.Header>
          <div className="mb-5">
            <Form.Fields.SearchableSelect
              name="selectedWorkatoFolder"
              items={workatoFolders.workato_folders.map(
                (workatoFolder: { name: string; id: number }) => ({
                  label: workatoFolder.name,
                  value: workatoFolder.id.toString(),
                }),
              )}
              isAsync={true}
              isLoading={isLoading || isCreating}
            />
          </div>
        </>
      </Form.Provider>
    </div>
  );
};
