import { useState, useMemo, useContext } from "react";

// UI Library Components
import { cn, useModalToggle } from "@trunk-tools/ui";
import {
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
  Popper,
  ListItemIcon,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";

// Icons
import {
  HiOutlineDocumentReport,
  HiOutlineTrash,
  HiOutlineFolderOpen,
} from "react-icons/hi";

// Local Components
import { CorpusFilterBlockSection } from "./CorpusFilterBlockComponents/CorpusFilterBlockSection.component";
import { CorpusFilterPopupTrigger } from "./CorpusFilterBlockComponents/CorpusFilterPopupTrigger.component";
import { CorpusFilterParagraphSeparator } from "./CorpusFilterBlockComponents/CorpusFilterParagraphSeparator.component";
import { CorpusFilterFoldersAndFiles } from "./CorpusFilterBlockComponents/CorpusFilterFoldersAndFiles.component";
import { UACProjectSelector } from "./CorpusFilterBlockComponents/UACProjectSelector.component";

// Constants
import { ExternalDocumentType } from "@trunk-tools/txt-shared";
import {
  CORPUS_FILTERLIST_ITEM_ICON_COMMON_PROPS,
  CORPUS_FILTER_POPPER_MODIFIERS,
} from "./CorpusFilterBlockComponents/CorpusFilterBlockComponents.constants";

// Types
import { LocalCorpusFilterFilter } from "./CorpusFilters.types";
import { CorpusFiltersContext } from "./CorpusFilters.context";

// Utils
import { pluralize } from "@/utils/pluralize";
import { CORPUS_FILTER_BLOCK_CLASSNAMES_ROOT } from "./CorpusFilterBlock.constants";

const docTypesOptions = Object.values(ExternalDocumentType).map((docType) => {
  let humanReadableLabel;
  switch (docType) {
    case "rfi":
      humanReadableLabel = "RFIs";
      break;
    case "sop":
      humanReadableLabel = "SOPs";
      break;
    case "meeting_notes":
      humanReadableLabel = "Meeting Notes";
      break;
    case "general":
      humanReadableLabel = "General";
      break;
    case "other":
      humanReadableLabel = "Other";
      break;
    default:
      humanReadableLabel = docType
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      humanReadableLabel = `${humanReadableLabel}s`;
      break;
  }

  return {
    id: docType,
    label: humanReadableLabel,
  };
});

export const CorpusFilterBlock = ({
  filters,
  isOnlyFilterBlock = false,
}: {
  filters: LocalCorpusFilterFilter;
  isOnlyFilterBlock: boolean;
}) => {
  const [docTypesAnchorEl, setDocTypesAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const {
    close: closeFileAndFoldersDialog,
    open: openFileAndFoldersDialog,
    isOpen: isFileAndFoldersDialogOpen,
  } = useModalToggle();

  const { allUACProjects, onFilterBlockChange, onFilterBlockDelete } =
    useContext(CorpusFiltersContext);

  // Track selected UAC project label
  const selectedUACProjectLabel = useMemo(() => {
    const uacProject = allUACProjects.find(
      (uacProject) => uacProject.id === filters.document_source_system,
    );

    return uacProject?.name || "All Integrations";
  }, [filters.document_source_system, allUACProjects]);

  // Track selected document types
  const selectedDocTypes = filters.document_types || [];

  // Handler for individual document type selection
  const handleDocTypeToggle = ({
    docTypeId,
  }: {
    docTypeId: ExternalDocumentType;
  }) => {
    const newSelectedDocTypes = selectedDocTypes.includes(docTypeId)
      ? selectedDocTypes.filter((id) => id !== docTypeId)
      : [...selectedDocTypes, docTypeId];

    onFilterBlockChange({
      filters: {
        ...filters,
        document_types: newSelectedDocTypes,
      },
    });
  };

  // Handler for "Select All" toggle
  const handleSelectAllToggle = () => {
    const allDocTypeIds = docTypesOptions.map((opt) => opt.id);
    const newSelectedDocTypes =
      selectedDocTypes.length === docTypesOptions.length ? [] : allDocTypeIds;

    onFilterBlockChange({
      filters: {
        ...filters,
        document_types: newSelectedDocTypes,
      },
    });
  };

  const { docTypesLabel, docTypesHasSelection } = useMemo(() => {
    const selectedDocTypesLength = selectedDocTypes.length;
    if (
      !selectedDocTypesLength ||
      selectedDocTypesLength === docTypesOptions.length
    ) {
      return { docTypesLabel: "All Doc Types", docTypesHasSelection: false };
    }

    if (selectedDocTypesLength <= 2) {
      return {
        docTypesLabel: selectedDocTypes
          .map((id) => docTypesOptions.find((opt) => opt.id === id)?.label)
          .filter(Boolean)
          .join(", "),
        docTypesHasSelection: true,
      };
    }

    return {
      docTypesLabel: `${selectedDocTypes
        .slice(0, 2)
        .map((id) => docTypesOptions.find((opt) => opt.id === id)?.label)
        .filter(Boolean)
        .join(", ")}, +${selectedDocTypesLength - 2}`,
      docTypesHasSelection: true,
    };
  }, [selectedDocTypes, docTypesOptions]);

  const selectedFoldersAndFilesCount =
    (filters.folder_ids?.length || 0) + (filters.document_ids?.length || 0);

  return (
    <div
      className={cn(
        CORPUS_FILTER_BLOCK_CLASSNAMES_ROOT,
        "flex flex-col gap-6 relative group",
      )}
    >
      <Tooltip
        title={`${isOnlyFilterBlock ? "Clear" : "Delete"} Filter Block`}
        placement="top"
        arrow
      >
        <IconButton
          onClick={() => {
            onFilterBlockDelete({ filterBlockId: filters._uuidKey });
          }}
          className="absolute top-2 right-2 desktop:hidden group-hover:block p-0"
        >
          <HiOutlineTrash className="text-red-700" />
        </IconButton>
      </Tooltip>
      <CorpusFilterBlockSection>
        <CorpusFilterParagraphSeparator content="Search" />
        <UACProjectSelector
          label={selectedUACProjectLabel}
          showFilteredUACProjectsOnly={false}
          onSelect={({ uacProjectId }) => {
            onFilterBlockChange({
              filters: {
                ...filters,
                document_source_system: uacProjectId,
                folder_ids: [],
                document_ids: [],
              },
            });
          }}
        />
        <CorpusFilterParagraphSeparator content="for" />
        <CorpusFilterPopupTrigger
          label={docTypesLabel}
          iconOrImg={<HiOutlineDocumentReport className="w-6 h-6 text-white" />}
          iconContainerClassName="bg-[#005A9C] text-white rounded-l-sm"
          onClick={(event) => {
            setDocTypesAnchorEl(event.currentTarget);
          }}
          showClear={docTypesHasSelection}
          onClear={() => {
            onFilterBlockChange({
              filters: {
                ...filters,
                document_types: [],
              },
            });
          }}
        />
        <Popper
          open={Boolean(docTypesAnchorEl)}
          anchorEl={docTypesAnchorEl}
          placement="top"
          modifiers={CORPUS_FILTER_POPPER_MODIFIERS}
          className="in-dialog"
        >
          <ClickAwayListener
            onClickAway={() => {
              setDocTypesAnchorEl(null);
            }}
          >
            <div>
              <p className="uppercase text-sm text-tt-modal-text-muted mt-4 px-4">
                Select Document Types
              </p>
              <List className="pb-2 overflow-y-auto max-h-[500px]">
                <ListItemButton
                  dense
                  onClick={handleSelectAllToggle}
                  sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <ListItemIcon {...CORPUS_FILTERLIST_ITEM_ICON_COMMON_PROPS} />
                  <ListItemText primary="Select All" />
                  <Checkbox
                    edge="end"
                    checked={selectedDocTypes.length === docTypesOptions.length}
                  />
                </ListItemButton>
                {docTypesOptions.map((docTypeOption) => (
                  <ListItemButton
                    key={docTypeOption.id}
                    onClick={() =>
                      handleDocTypeToggle({ docTypeId: docTypeOption.id })
                    }
                    dense
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <ListItemIcon {...CORPUS_FILTERLIST_ITEM_ICON_COMMON_PROPS}>
                      <HiOutlineDocumentReport className="w-4.5 h-4.5" />
                    </ListItemIcon>
                    <ListItemText primary={docTypeOption.label} />
                    <Checkbox
                      edge="end"
                      checked={selectedDocTypes.includes(docTypeOption.id)}
                    />
                  </ListItemButton>
                ))}
              </List>
            </div>
          </ClickAwayListener>
        </Popper>
        <CorpusFilterParagraphSeparator content="and" />
        <CorpusFilterPopupTrigger
          label={
            selectedFoldersAndFilesCount
              ? `${selectedFoldersAndFilesCount} ${pluralize({
                  singular: "Folder",
                  plural: "Folders",
                  count: selectedFoldersAndFilesCount,
                })} & ${pluralize({
                  singular: "File",
                  plural: "Files",
                  count: selectedFoldersAndFilesCount,
                })}`
              : "All Folders & Files"
          }
          iconOrImg={<HiOutlineFolderOpen className="w-6 h-6 text-brand" />}
          iconContainerClassName="bg-black rounded-l-sm"
          disabled={!filters.document_source_system}
          onClick={openFileAndFoldersDialog}
          showClear={selectedFoldersAndFilesCount > 0}
          onClear={() => {
            onFilterBlockChange({
              filters: {
                ...filters,
                folder_ids: [],
                document_ids: [],
              },
            });
          }}
        />
        {filters.document_source_system && isFileAndFoldersDialogOpen && (
          <CorpusFilterFoldersAndFiles
            filters={filters}
            uacProjectLabel={selectedUACProjectLabel}
            onClose={closeFileAndFoldersDialog}
          />
        )}
      </CorpusFilterBlockSection>
    </div>
  );
};
