import { useContext, useState } from "react";

// MUI Components
import {
  Popper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";

// Local Components
import { CorpusFilterPopupTrigger } from "./CorpusFilterPopupTrigger.component";

// Icons
import syncIcon from "public/sync.svg";

// Constants
import {
  CORPUS_FILTERLIST_ITEM_ICON_COMMON_PROPS,
  CORPUS_FILTER_POPPER_MODIFIERS,
} from "./CorpusFilterBlockComponents.constants";
import { CorpusFiltersContext } from "../CorpusFilters.context";

export const UACProjectSelector = ({
  label,
  showFilteredUACProjectsOnly,
  onSelect,
}: {
  label: string;
  showFilteredUACProjectsOnly: boolean;
  onSelect: ({ uacProjectId }: { uacProjectId: string }) => void;
}) => {
  const [uacProjectsAnchorEl, setUacProjectsAnchorEl] =
    useState<null | HTMLElement>(null);
  const { availableUACProjectsByService, allUACProjectsByService } =
    useContext(CorpusFiltersContext);

  const uacProjects = showFilteredUACProjectsOnly
    ? availableUACProjectsByService
    : allUACProjectsByService;

  return (
    <>
      <CorpusFilterPopupTrigger
        label={label}
        iconOrImg={
          <img src={syncIcon} alt="sync" className="w-6 h-6 text-black" />
        }
        iconContainerClassName="bg-brand-dark rounded-l-sm"
        onClick={(event) => {
          setUacProjectsAnchorEl(event.currentTarget);
        }}
      />
      <Popper
        open={Boolean(uacProjectsAnchorEl)}
        anchorEl={uacProjectsAnchorEl}
        placement="top"
        modifiers={CORPUS_FILTER_POPPER_MODIFIERS}
        className="in-dialog"
      >
        <ClickAwayListener
          onClickAway={() => {
            setUacProjectsAnchorEl(null);
          }}
        >
          <div>
            <p className="uppercase text-sm text-tt-modal-text-muted mt-4 px-4">
              Select Integration
            </p>
            {uacProjects.map((projectOption) => (
              <List key={projectOption.id} className="pb-4">
                <ListItem className="px-12">
                  <ListItemIcon {...CORPUS_FILTERLIST_ITEM_ICON_COMMON_PROPS}>
                    <img src={syncIcon} alt="sync" />
                  </ListItemIcon>
                  <ListItemText primary={projectOption.serviceLabel} />
                </ListItem>
                {projectOption.uacProjects.map((child) => (
                  <ListItemButton
                    key={child.id}
                    className="px-12 hover:bg-[#005A9C] hover:text-white"
                    onClick={() => {
                      onSelect({ uacProjectId: child.id });
                      setUacProjectsAnchorEl(null);
                    }}
                  >
                    <ListItemIcon
                      {...CORPUS_FILTERLIST_ITEM_ICON_COMMON_PROPS}
                    />
                    <ListItemText primary={child.name} />
                  </ListItemButton>
                ))}
              </List>
            ))}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
