import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { CorpusFilter } from "@trunk-tools/txt-shared";
import { useDeleteCorpusFilter } from "dataHooks";

export const DeleteFilterModal = ({
  onClose,
  onSuccess,
  corpusFilterId,
  corpusFilterName,
}: {
  onClose: () => void;
  onSuccess: () => void;
  corpusFilterId: CorpusFilter["id"];
  corpusFilterName: CorpusFilter["name"];
}) => {
  const { trigger: deleteCorpusFilter, isMutating } = useDeleteCorpusFilter({
    onSuccess,
  });

  const handleDelete = async () => {
    if (corpusFilterId) {
      await deleteCorpusFilter({ corpusFilterId });
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Filter</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete "{corpusFilterName}"? This cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          disabled={isMutating}
          fullWidth
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          color="error"
          disabled={isMutating}
          data-pendo-id="corpus_filter_delete"
          fullWidth
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
