import {
  CorpusFilterFiltersSchema,
  GetUACProjectsResponseType,
} from "@trunk-tools/txt-shared";
import { LocalCorpusFilter } from "./CorpusFilters.types";
import { captureMessage } from "@sentry/react";

const sortArrayIfPrimitives = (arr: unknown[]): unknown[] => {
  /**
   * Check if array contains only primitives, which can be
   * sorted.
   */
  if (
    arr.every((item) => ["string", "number", "boolean"].includes(typeof item))
  ) {
    return [...arr].sort();
  }

  return arr;
};

// Compares two arrays for deep equality, handling nested arrays and objects
const areArraysEqual = (a: unknown[], b: unknown[]): boolean => {
  // Quick length check - if lengths differ, arrays can't be equal
  if (a.length !== b.length) return false;

  // If arrays contain primitives, sort them first for comparison
  const arrA = sortArrayIfPrimitives(a);
  const arrB = sortArrayIfPrimitives(b);

  // Compare each element in the arrays
  return arrA.every((itemA, index) => {
    const itemB = arrB[index];

    // Recursively compare nested arrays
    if (Array.isArray(itemA) && Array.isArray(itemB)) {
      return areArraysEqual(itemA, itemB);
    }

    // Recursively compare nested objects
    if (typeof itemA === "object" && typeof itemB === "object") {
      return deepCompare(itemA, itemB);
    }

    // Direct comparison for primitives
    return itemA === itemB;
  });
};

// Performs a deep comparison between two values of any type
const deepCompare = (a: unknown, b: unknown): boolean => {
  // Handle null values
  if (a === null || b === null) {
    return a === b;
  }

  // Handle arrays by delegating to areArraysEqual
  if (Array.isArray(a) && Array.isArray(b)) {
    return areArraysEqual(a, b);
  }

  // Handle objects (excluding null and arrays which were handled above)
  if (typeof a === "object" && typeof b === "object") {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    // Quick check - objects must have same number of keys
    if (keysA.length !== keysB.length) return false;

    // Compare each key-value pair recursively
    return keysA.every((key) => {
      const valA = a[key];
      const valB = b[key];

      // Special case for array values
      if (Array.isArray(valA) && Array.isArray(valB)) {
        return areArraysEqual(valA, valB);
      }

      // Recursively compare other value types
      return deepCompare(valA, valB);
    });
  }

  // Direct comparison for primitives
  return a === b;
};

export const corpusFiltersAreDifferent = ({
  corpusFilterA,
  corpusFilterB,
}: {
  corpusFilterA: Partial<LocalCorpusFilter>;
  corpusFilterB: Partial<LocalCorpusFilter>;
}) => {
  const parsedCorpusFilterAFilters = CorpusFilterFiltersSchema.safeParse(
    corpusFilterA.filters,
  );
  const parsedCorpusFilterBFilters = CorpusFilterFiltersSchema.safeParse(
    corpusFilterB.filters,
  );

  if (
    !parsedCorpusFilterAFilters.success ||
    !parsedCorpusFilterBFilters.success
  ) {
    captureMessage("Failed to parse corpus filters");
    return false;
  }

  return !deepCompare(
    parsedCorpusFilterAFilters.data,
    parsedCorpusFilterBFilters.data,
  );
};

export const convertUACProjectsToTree = ({
  uacProjects,
}: {
  uacProjects: GetUACProjectsResponseType;
}) => {
  return Object.entries(
    uacProjects.reduce((acc, uacProject) => {
      const sourceSystem = uacProject.uac_account.source_system;
      if (!acc[sourceSystem]) {
        acc[sourceSystem] = [];
      }
      acc[sourceSystem].push(uacProject);
      return acc;
    }, {} as Record<string, typeof uacProjects>),
  ).map(([sourceSystem, uacProjects]) => ({
    id: sourceSystem,
    serviceLabel: sourceSystem,
    uacProjects,
  }));
};
