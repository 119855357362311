import { useInterval } from "@/hooks/useInterval";
import { api } from "../helpers/APIClient";
import { UACAccountType } from "@trunk-tools/txt-shared/src/schemas/uac.schema";
import { useEffect, useState } from "react";
import { toast } from "@trunk-tools/ui";

export const useGetUACPotentialProjects = (uacAccount: UACAccountType) => {
  const [uacAccountStatus, setUACAccountStatus] = useState<
    "Ready" | "Initializing" | "Failed" | undefined
  >();
  const [isLoadingPotentialProjects, setIsLoadingPotentialProjects] =
    useState(false);
  const [potentialProjects, setPotentialProjects] = useState<
    | {
        id: string;
        name: string;
      }[]
    | undefined
  >(undefined);
  const [potentialProjectsSearch, setPotentialProjectsSearch] = useState("");

  useInterval(
    async () => {
      const result = await api.getUACAccountStatus({
        uac_account_id: uacAccount.id,
      });
      setUACAccountStatus(result.lifecycle_status);
      if (result.lifecycle_status === "Ready") {
        setIsLoadingPotentialProjects(true);
        const projects = await api.getUACPotentialProjects({
          accountId: uacAccount.id,
        });
        setPotentialProjects(projects.potential_projects);
        setIsLoadingPotentialProjects(false);
      } else if (result.lifecycle_status === "Failed") {
        toast.error("Account setup failed, please contact support");
      }
    },
    uacAccount && (!uacAccountStatus || uacAccountStatus === "Initializing")
      ? 2000
      : null,
  );

  useEffect(() => {
    if (!uacAccount) return;
    const fetchPotentialProjects = async (searchStr: string) => {
      setIsLoadingPotentialProjects(true);
      const result = await api.getUACPotentialProjects({
        accountId: uacAccount.id,
        ...(potentialProjectsSearch ? { search: searchStr } : {}),
      });
      setPotentialProjects(result.potential_projects);
      setIsLoadingPotentialProjects(false);
    };
    fetchPotentialProjects(potentialProjectsSearch);
  }, [potentialProjectsSearch]);

  return {
    isLoadingPotentialProjects,
    potentialProjects: potentialProjects || [],
    setPotentialProjectsSearch,
  };
};
